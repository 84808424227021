@import '~assets/styles/variables';

.paginator {
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding: 4px 16px;
    background-color: $color--background;
    border-top: $border--small;
    min-height: 50px;
  }

  &__dropdown {
    min-width: 40px;
    margin-right: 6px;
  }

  &__item {
    margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
