@import '~assets/styles/variables';

.notes {
  max-width: 100%;
}

.content {
  border: $rule;
  border-radius: 4px;
  margin: $spacing;
  background: $palette--bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}
