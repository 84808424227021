@import '~assets/styles/partials/helpers';

.frame {
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.sidebar {
  display: flex;
  flex-flow: column;
  border-right: $border--small;
  background-color: $color--white;
  width: 280px;
  height: 100%;
  @include transition-in(width, 200ms);
  &--collapsed {
    @include transition-in(width);
    width: 100px;
  }
}

.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @include layout--medium {
    flex-direction: column;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.banners {
  position: relative;
  flex: 0 0 auto;

  > * {
    margin: 1rem;
  }
}
