@import '~assets/styles/variables';

.item {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  &__palette {
    overflow: hidden;
    width: 125px;
    height: 20px;
    display: flex;
    border-radius: 0.5rem;
    border: $rule;
    margin-left: 0.5rem;
    padding: 0;
    > * {
      flex: 1;
      height: 100%;
    }
  }
  &__select {
    margin: 0;
  }
  &__label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
}
