@import '~assets/styles/variables';

.container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  flex: 0;
  background-color: $color--white;
  box-shadow: 0px -1px 0px 0px #e0e0e0;
  z-index: 1;
}

.meta {
  display: flex;
  flex-direction: column;
}

.label {
  color: $palette--heading;

  strong {
    color: $palette--text;
    font-weight: 700;
    margin-right: 2px;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 0.25rem;

    strong {
      color: $palette--heading;
      font-weight: 700;
    }
  }

  .control {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 36px;
  }
}
