@import '~assets/styles/variables';

.scan {
  width: 100%;
  padding: 1rem;
  display: block;
  text-align: center;

  .pdf {
    overflow: hidden;
    user-select: none;

    .viewer {
      outline: none;
    }
    &--top {
      padding-top: 1rem;
    }
    &--bottom {
      padding-bottom: 1.5rem;
    }
  }
  .title {
    margin: 0;
  }
  .label {
    color: $palette--text--secondary;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
