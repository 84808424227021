@import '~assets/styles/variables';

.browser {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  min-height: 800px;
  box-shadow: 0px -1px 0px 0px $color--gray--light;
}

.panel {
  flex: 1;
  z-index: 3;
}
