@import '~assets/styles/variables';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: $color--primary--light;
  position: relative;
  padding: 8rem 4rem;

  @include layout--medium {
    padding: 6rem 3rem;
  }

  @include layout--small {
    display: none;
  }

  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('~assets/images/login.jpg');
    background-size: cover;
    background-position: center center;
    opacity: 0.4;
    z-index: 0;
    mix-blend-mode: overlay;
    filter: contrast(1.5) grayscale(1);
  }

  .title {
    color: $color--white;
    margin-bottom: 1rem;
    max-width: 600px;
  }

  .message {
    color: $color--white;
    max-width: 600px;
  }
}

.panel {
  width: 480px;
  background-color: $color--white;
  padding: 5rem 3rem;

  @include layout--medium {
    width: 360px;
    padding: 4rem 2rem;
  }

  @include layout--small {
    width: 100%;
  }

  .logo {
    height: 64px;
  }

  .form {
    padding-top: 3rem;

    @include layout--medium {
      padding-top: 1rem;
    }
  }
}
