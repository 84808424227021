@import '~assets/styles/variables';

.permissions {
  display: flex;
  &__icon {
    color: $color--primary--light;
    &__container {
      padding: 1rem 2rem 0 0;
      &--disabled {
        opacity: 0.6;
        .permissions__icon {
          color: $color--gray;
        }
      }
    }
    &:global(.md-icon) {
      font-size: 24px;
    }
  }
}
