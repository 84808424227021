$rule: 0.5px solid $palette--gray--light;
$rule--full: 1px solid $palette--gray--light;

$box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.12),
  0px 2px 2px 0px rgba(0, 0, 0, 0.24);
$box-shadow--menu: 0 0 8px 0 rgba(0, 0, 0, 0.12),
  0 8px 8px 0 rgba(0, 0, 0, 0.24);
$box-shadow--card: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow--drawer--right: -2px 2px 4px 0 rgba(0, 0, 0, 0.12);
