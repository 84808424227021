@import '~assets/styles/variables';

.label {
  width: 100px;
  background-color: $palette--bg--secondary;
  color: $palette--text--secondary;
  text-align: center;
  border-radius: 0 0 4px 4px;
  border-left: $rule;
  border-bottom: $rule;
  border-right: $rule;
  margin-left: auto;
  margin-right: 2rem;
  padding: 0.5rem;
  font-weight: bold;
}
