.read-icon::after {
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  border: 1.5px solid $palette--text--disabled;
  border-radius: 50%;
  margin-top: 0.8em;
  margin-right: 3em;
}

.unread-icon::after {
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  background: $palette--accent;
  border: 1.5px solid $palette--accent;
  border-radius: 50%;
  margin-top: 0.8em;
  margin-right: 3em;
}

.alert-icon::after {
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  background: $palette--accent;
  border: 1.5px solid $palette--accent;
  border-radius: 50%;
  margin-top: 0.8em;
  margin-right: 3em;
  background: $palette--warn;
  border: 1.5px solid $palette--warn;
  margin: 0;
}
