@import '~assets/styles/variables';

.card {
  display: grid;
  grid-template-columns: repeat(2, 1fr) minmax(100px, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 2rem;
  align-items: center;
  padding: 1rem 0 1rem 2rem;

  @media (max-width: $screen-md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    padding: 1rem 2rem;
  }

  h1 {
    @extend %font__h2;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  span {
    color: $palette--text--secondary;
    padding: 0.5rem 0;

    &:nth-of-type(1) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    &:nth-of-type(2) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  div {
    grid-column: 3 / 4;
    grid-row: 1 / 3;

    @media (max-width: $screen-md) {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
      justify-content: flex-start;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}
