@import '~assets/styles/variables';

.content {
  width: 100%;
}

.instructions {
  margin-top: 0;
  > li {
    margin-right: 1rem;
  }
}

.loadbar {
  height: 8px;
  margin-top: 1em;
}

.label {
  @extend %font__small;
  margin-bottom: 5px;
}

.warning {
  color: $color--negative;
}