@import '~assets/styles/variables';

$max-panel-height: 50vh;

.main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-top: $border--small;
    border-bottom: $border--small;
    max-height: $max-panel-height;
    height: $max-panel-height;
}

.leftpanel {
    max-height: $max-panel-height;
    overflow-y: scroll;
}

.stickyblock {
    position: sticky;
    top: 0;
    background: $color--background--lightest;
    border-bottom: $border--small;
}

.rightpanel {
    border-left: $border--small;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.expandable {
    background: $color--background--lightest;
    &:hover {
        cursor: pointer;
    }
}

.sublist {
    background: $color--gray--light;
    padding-left: 1rem;
    &__content {
        padding-inline-start: 0;
        background: $color--background--dark;
    }
}

.scanner {
    &__feedback {
        &__block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__icon {
            color: $color--primary;
            font-size: 3rem;
            margin-top: 2rem;
        }
        &--positive {
            color: $color--positive;
        }
        &--negative {
            color: $color--negative;
        }
    }
}
