@import '~assets/styles/variables';

.upload {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  display: block;
  border-radius: 5px;
  border: 2px dashed $color--accent;
  background-color: rgba(lighten($color--accent, 25%), 0.7);
  backdrop-filter: blur(5px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  .message {
    font-family: $font-family--display;
    font-size: 1.6rem;
    font-weight: 500;
    color: darken($color--accent, 15%);
  }

  &--loading {
    pointer-events: all;
  }

  &--error {
    border-color: $color--negative;
    background-color: rgba($color--negative, 0.2);

    .message {
      color: darken($color--negative, 10%);
    }
  }
}

.loadbar {
  margin-top: 2rem;
  max-width: 50%;
}

.button {
  margin-top: 2rem;
}
