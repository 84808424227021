@import '~assets/styles/variables';

.tile {
  display: flex;
  flex-direction: column;
  min-height: calc(25vw + 47px);
  background-color: $color--white;
  margin: 2rem;
  border: $border--small;
  border-radius: $border-radius--small;
  box-shadow: $shadow--medium;
  &:hover {
    cursor: pointer;
    box-shadow: $shadow--large;
    @include transition-in(box-shadow, 200ms);
  }
  @include layout--small {
    min-height: 50vw;
  }
  .title {
    text-align: center;
    color: $color--primary--light;
    padding: $spacing--medium;
  }
}
