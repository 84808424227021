@import '~assets/styles/variables';

.container {
  width: 100%;
  padding: 1rem 2rem;
}

.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.breadcrumb {
  text-transform: uppercase;
  font-size: 11px;
  font-family: $font-family--display;
  line-height: 2;
  display: flex;
  align-items: center;
  padding: 2px 0;

  a {
    font-weight: 400;
    color: $palette--text--secondary;
    padding: 0 5px;
  }

  span {
    font-weight: 600;
    color: $palette--accent;
    padding: 0 5px;
  }

  .chevron {
    color: $palette--heading;
    display: flex;
    align-items: center;
    padding: 0 5px;

    svg {
      font-size: 8px !important;
    }
  }
}
