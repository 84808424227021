@import '~assets/styles/variables';

.tree {
  position: relative;
  padding-left: 1rem;
  padding-top: 0.5rem;
  margin-bottom: -0.5rem;
  width: fit-content;

  &--root {
    padding: 0;

    > .line {
      display: none;
    }
  }
}

.line {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  top: 0.5rem;
  left: 0.5rem;
  border-left: 2px solid rgba(0, 0, 0, 0.06);
}
