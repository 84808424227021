@import '~assets/styles/variables';

.header {
  border-top: $rule;
  background-color: #fff;
  padding: 1.5rem 0;
  display: flex;

  .message {
    flex: 1;
    padding: 0rem 2rem;

    .title {
      display: block;
      padding-bottom: 1rem;
      color: $palette--heading;
    }
    .text {
      display: block;
      color: $palette--text;
      ol {
        padding-left: 2rem;
      }
    }
  }

  .details {
    flex: 1;
    padding-right: 2rem;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: $rule;
        padding: 0.75rem 0 0.75rem 2rem;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    .label {
      text-transform: uppercase;
      color: $palette--text--secondary;
    }
  }
}
