$column-padding: 16px;
$border: 1px solid $palette--gray--light;
$header-background-color: $palette--header-bg;
$row-background-even: #f3f9fa;
$row-background-odd: #fbfcfd;
$show-frozen-rows-shadow: false;
$show-frozen-columns-shadow: false;
$row-hovered-background-color: lighten($palette--bg--secondary, 3%);
$header-cell-hovered-background-color: $palette--header-bg;
$sort-indicator-size: 24px;
$column-overflow-buffer: 300px;

@import 'node_modules/react-base-table/es/_BaseTable.scss';

.#{$table-prefix} {
  &__header-cell,
  &__row-cell {
    color: $palette--text;
    border-right: $border;
    overflow: visible !important;

    &-text {
      width: 100%;
    }
    &-subtitle {
      @extend .#{$table-prefix}__row-cell-text;
      @extend .muted;
      @extend %font__small;
    }
    position: relative;
  }

  &__header-cell {
    color: $palette--text--secondary;
    &--sortable {
      justify-content: space-between;
    }
  }

  &__row-cell {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &--align-center {
      align-items: center;
    }
  }

  &__empty {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $palette--bg;
  }

  &__overlay {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    &-header {
      width: 100%;
      background-color: $palette--bg;
      opacity: 0.25;
    }
    &-body {
      width: 100%;
      background-color: $palette--bg;
      opacity: 0.75;
      flex-grow: 1;
    }

    .default-error-card {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card__error {
        opacity: 1;
        background-color: $palette--header-bg;
      }
      &__close {
        align-self: flex-end;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__table {
    &-frozen-left,
    &-frozen-right {
      overflow: visible !important;

      .#{$table-prefix}__header,
      .#{$table-prefix}__body {
        width: inherit !important;
        & > div {
          width: inherit !important;
          .#{$table-prefix}__row {
            width: inherit !important;
          }
        }
      }
      .#{$table-prefix}__body {
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
    &-frozen-right {
      .#{$table-prefix}__body {
        padding-left: $column-overflow-buffer;
      }
      .#{$table-prefix}__row-cell,
      .#{$table-prefix}__header-cell {
        border-right: none;
        border-left: $border;
      }
    }
    &-frozen-left {
      .#{$table-prefix}__body {
        padding-right: $column-overflow-buffer;
      }
    }
  }
  &__row {
    border-bottom: none;
    overflow: visible !important;
    &.row-even {
      background-color: $row-background-even;
    }

    &.row-odd {
      background-color: $row-background-odd;
    }

    &:hover,
    &--hovered {
      &.row-even,
      &.row-odd {
        background-color: $row-hovered-background-color;
      }
    }
  }
}

.custom-header-cell-inner {
  min-width: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.custom-header-cell-text {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BaseTable__table-frozen-left .BaseTable__body {
  padding-right: 500px;
  pointer-events: none;
}

.BaseTable__table-frozen-right .BaseTable__body {
  pointer-events: none;
}

.BaseTable__table-frozen-left .BaseTable__body > * {
  pointer-events: all;
}

.BaseTable__table-frozen-right .BaseTable__body > * {
  pointer-events: all;
}

.border-cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  margin-left: -16px;
  padding: 0 14px;
  &--warn {
    @extend .border-cell;
    border: 2px solid $palette--warn;
    color: $palette--warn;
  }
}
