@import '~assets/styles/variables';

.container {
  width: 280px;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2em;
  & button {
    border: 1px solid #949494;
  }
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing;
}

.page-indicators {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
