@import '~assets/styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel {
  background-color: $color--background;
  border-top: $border--medium;
  border-bottom: $border--small;
  max-height: 0;
  opacity: 0;
  @include transition-in;
  &--open {
    max-height: none;
    opacity: 1;
  }
}
