@import '~assets/styles/variables';

.container {
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: grab;
  &--disabled {
    cursor: inherit;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: grabbing;
  z-index: 20;
}

.interface {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 0 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.button {
  box-shadow: $shadow--large;
  margin-bottom: 1.4rem;
}
