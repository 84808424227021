@import '~assets/styles/variables';

.toolbar {
  display: flex;
  flex-flow: row wrap;
  column-gap: 1rem;
  padding: 1rem;
  .state {
    flex: 4 300px;
  }
  .county {
    flex: 1 150px;
  }
  .state {
    flex: 1 150px;
  }
  .cluster {
    flex: 1 150px;
    margin-top: 1rem;
  }
}

.indicator {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 20px;
  border: 2px solid $palette--text--disabled;
  position: relative;
  background-color: $color--white;
  &--active {
    border-color: $palette--accent;
    &::after {
      content: '';
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: $palette--accent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
