.ellipsis-modal-cell {
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  margin-right: -8px;
  &__cell-data {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    padding-right: 0.5rem;
  }
  i {
    font-size: 1em;
  }
  button:hover {
    background: none;
    opacity: 0.7;
  }
}
