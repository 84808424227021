@import '~assets/styles/variables';

.container {
  width: 100%;
}

.field {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin-bottom: 2px;
  font-size: 14px;
  &--focused,
  &--has-value {
    .label {
      top: -6px;
      font-size: 13px;
    }
  }
  &--focused {
    .label {
      color: $color--gray--darkest;
    }
  }
  &--error {
    .label {
      color: $color--negative;
    }
    :global .ReactSelect__control {
      border-bottom: thin solid $color--negative;
    }
  }
}

.label {
  position: absolute;
  top: 10px;
  z-index: 1;
  color: $color--gray--dark;
  transition: top 200ms ease-out;
}

.help {
  @extend %font__small;
  color: $color--gray--dark;
}

.error {
  @extend %font__small;
  color: $color--negative;
}

// React-select overrides
.container,
body {
  :global {
    .ReactMultiSelect {
      &__indicator-separator {
        display: none;
      }

      &__control {
        border-radius: 0;
        background-color: transparent !important;
        border: none;
        border-bottom: $border--small;
        min-height: 36px;
        align-items: flex-end;
        box-shadow: none;

        &:hover {
          border-color: $color--gray--light;
        }

        &--is-focused {
          .ReactMultiSelect {
            &__value-container {
              > div:last-child {
                margin: 0 2px 1px;
                width: auto;
                height: auto;
                padding: 2px 0;
              }

              &--has-value {
                > div:last-child {
                  margin: 0 2px 9px;
                }
              }
            }
          }
        }
      }

      &__value-container {
        padding: 0;

        > div:last-child {
          margin: 0;
          width: 0;
          height: 0;
          padding: 0;
        }

        &--has-value {
          .ReactSelect__single-value {
            color: $color--gray--darkest;

            &--is-disabled {
              color: $color--gray;
            }
          }
        }
      }

      &__multi-value {
        color: $color--gray--dark;
        background-color: $color--background--dark;
        border-radius: $border-radius--medium;
        margin-top: 0;
        margin-left: 0;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 1px;

        &:first-of-type {
          margin-top: 18px;
        }

        &--is-disabled {
          color: $color--gray;
        }

        &__label {
          padding-left: 10px;
          padding-right: 4px;
        }

        &__remove {
          padding-right: 6px;
          cursor: pointer;

          i {
            font-size: 13px;
            color: $color--gray;
            border-radius: 100px;
            background-color: $color--gray--light;
            padding: 2px;

            &:hover {
              color: $color--gray--dark;
              background-color: $color--gray--lightest;
            }
          }
          &:hover {
            background-color: $color--background--dark;
          }
        }
      }

      &__menu {
        border-radius: 0;
        margin: 0;
        border: $border--small;
        z-index: 99;
      }

      &__menu-list {
        padding: 0;
        box-shadow: $shadow--medium;
      }

      &__option {
        &:active {
          background-color: $color--background--light;
        }

        &--is-focused {
          background-color: $color--background;

          &:active {
            background-color: $color--background--light;
          }
        }

        &--is-selected {
          color: $color--accent;
          background-color: $color--accent--light;

          &:active {
            background-color: $color--background--light;
          }
        }
      }

      &__option {
        font-size: 14px;
      }

      &__placeholder {
        display: none;
      }

      &__value-container {
        align-items: flex-end;
      }

      &__indicators {
        align-items: flex-end;
      }

      &__loading-indicator {
        align-self: flex-end;
        height: 36px;
        align-items: center;
      }
    }
  }
}
