@import '~assets/styles/variables';

.block {
  padding: 1rem 2rem 2rem;
  p {
    border-bottom: $rule;
    padding-bottom: 1em;
  }
  .title {
    display: flex;
    border-bottom: $rule;
    padding-bottom: 1em;
    align-items: center;
    margin-bottom: 2em;
    button {
      margin-left: 1em;
    }
  }
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media #{$breakpoint--md} {
    grid-template-columns: 1fr;
  }
}

.switch {
  padding-top: 1rem;
}
