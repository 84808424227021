@import '~assets/styles/variables';

.item {
  margin: 10px;
}

.emptyMessageSpan {
  @extend %lowercase;
}

.multipleSelectionContainer {
  margin-top: 1em;
  padding-left: 3em;
}
