@import '~assets/styles/variables';

.card {
  z-index: +1;
}

.card:hover {
  box-shadow: $shadow--medium;
}

.eventImage {
  img {
    width: 100%;
  }
  max-height: 500px;
}

.post-registration-message {
  white-space: pre-line;
}
