.list {
  background-color: $palette--bg;
  &__header {
    @extend .muted;
    padding: $spacing;
  }
  &__item,
  &__header {
    padding: $spacing;
    &--selectable {
      @extend %selectable;
    }
    &--selected {
      background-color: $palette--gray--light;
    }
    & > *:last-child,
    .list__header > *:last-child {
      grid-column-end: -1;
      text-align: right;
    }
  }
  &__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: $rule;
    &:last-child {
      border-bottom: none;
    }
    .md-selection-control-container {
      margin-right: 1.5em;
    }
    &__content {
      display: flex;
      align-items: center;
      max-width: 600px;
      min-width: 0;
      padding: 4px;
      margin-right: auto;
      .primary-icon {
        position: relative;
        padding-top: 0.75rem;
        padding-right: 1.5em;
        margin-right: 1rem;
        .md-icon {
          font-size: 2.4rem;
        }
        img {
          width: 50px;
          height: auto;
          border-radius: 50%;
          border: thin solid $palette--gray--light;
        }
      }
      .text-content {
        position: relative;
        min-width: 0;
        .primary {
          display: flex;
        }
      }
      .md-selection-control-toggle {
        .md-icon {
          padding-top: 0;
        }
      }
      .primary {
        font-size: 18px;
        color: $palette--text;
        overflow-wrap: break-word;
      }
      .secondary {
        font-size: 14px;
        color: $palette--text--secondary;
        padding-top: 0.5em;
        overflow-wrap: break-word;
      }
    }
    &__details {
      margin-left: auto;
      padding: 1em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      &__additional-content {
        margin-right: 2em;
        display: flex;
        justify-content: flex-end;
      }
      &__additional-actions {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
      }
      .md-btn--raised,
      .md-btn--raised-disabled {
        min-width: 150px;
      }
      .md-icon-separator {
        text-align: right;
      }
      .md-icon {
        font-size: 2em;
      }
      .md-menu--select-field {
        text-align: left;
        .md-icon-separator {
          text-align: left;
        }
      }
    }
    &__alert-icon {
      align-self: flex-start;
      margin-left: 1rem;
    }
    &--compact {
      padding: 0 2rem;
    }
  }
  &--two-column {
    .list__item,
    .list__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }
}
