@import '~assets/styles/variables';

.header {
  position: sticky;
  padding: 1.5rem 2rem 1.25rem;
  z-index: 2;
  display: grid;
  grid-gap: 1.25em;
  top: -0.5rem;
  background-color: $color--background;
  box-shadow: none;
  transition: background-color 0.075s ease-in-out, box-shadow 0.075s ease-in-out;

  &--stuck {
    background-color: $color--white;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    @extend %font__h2;
    color: $color--gray--darkest;
    padding-right: 1rem;
  }

  .actions {
    display: flex;
    flex-direction: row;
    margin: -0.25rem 0;

    .action {
      padding: 0;
      width: 3rem;
      height: 3rem;
      flex: 0 1 3rem;
      margin-left: 0.5rem;

      svg {
        color: $color--gray;
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }
}
