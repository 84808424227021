@import '~assets/styles/variables';

.breakdown {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include layout--small {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  &__paginator {
    display: flex;
    align-items: center;
    @include layout--small {
      flex-flow: row wrap;
      align-items: flex-start;
    }
    &__select {
      width: 250px !important;
      margin: 1rem 2rem;
      @include layout--small {
        order: 3;
        width: auto;
        margin: 0 auto 2rem 0;
      }
    }
    &__button {
      @include layout--small {
        margin-right: 2rem;
      }
    }
    &__meta {
      @include layout--small {
        margin-right: auto;
      }
    }
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 2rem 0;
    grid-row-gap: 2rem;
    @include layout--medium {
      display: flex;
      flex-flow: row wrap;
    }
  }
  &__item {
    width: 100px;
    margin: $spacing--medium;
    @include layout--medium {
      width: auto;
      flex-grow: 1;
    }
    &__field {
      margin-top: 1rem;
    }
  }
}
