@import '~assets/styles/variables';

.sidebar {
  flex: 0 1 200px;
  overflow: auto;
  background-color: $color--background;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 0px 0px $color--gray--light;
  z-index: 2;
  border-top: $border--medium;
}

.locations {
  flex: 1;
  overflow: auto;

  &--loading {
    > * {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.message {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color--gray--dark;
}
