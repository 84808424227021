@import '~assets/styles/variables';

.panel {
  & > * {
    margin-bottom: 1rem;
  }
}
.experience {
  margin-left: 0;
  & > button {
    margin: 0 1.25rem;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: $color--warn--light;
    padding: 0;
    border: none;
    box-shadow: $shadow--small;
    &:hover {
      background: $color--warn;
      box-shadow: $shadow--medium;
      cursor: pointer;
    }
  }
  svg {
    height: auto;
    width: 100%;
  }
}
