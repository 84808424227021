@import '~assets/styles/variables';

.profile {
  background-color: white;
}

.label {
  @extend %label;
  line-height: 2;
}

.name {
  @extend %font__h3;
  color: $palette--heading;
}

.address {
  @extend %reading-text;
  color: $palette--text--secondary;
  padding-top: 4px;
}
