@import '~assets/styles/variables';

.calendar {
  position: relative;
  border-top: $rule--full;
  border-left: $rule--full;
  background-color: $color--white;
  padding-top: 2rem;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.columns {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.label {
  border-bottom: $rule--full;
  border-right: $rule--full;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-weight: 600;
  color: $palette--heading;
  background-color: $palette--bg;
}

.week {
  min-height: 50vh;
}
