@import '~assets/styles/variables';

.inbox-viewer {
  position: relative;
  display: flex;
  &__main {
    flex: 1;
    max-width: 2000px;
    .list__item__details {
      opacity: 1;
      transition: opacity 200ms ease-in 100ms;
    }
    @include transition-out(max-width);
  }
  &__sidebar {
    flex: 0;
    max-width: 0;
    overflow: hidden;
    border-left: $border--small;
    box-shadow: -2px 0 4px $color--gray--light;
    @include transition-out(max-width);
    &__header {
      height: 88px;
      padding: $spacing--medium;
      text-align: center;
      border-bottom: $border--small;
      box-shadow: $shadow--small;
      display: flex;
      justify-content: center;
      align-items: center;
      &--full-width {
        @extend .inbox-viewer__sidebar__header;
        position: relative;
        z-index: 2;
        border-top: $border--small;
      }
    }
  }
  &__collapse-bar {
    background: $color--background--light;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 88px;
    padding: $spacing--medium;
  }
  &--open {
    .inbox-viewer__main {
      max-width: 300px;
      word-break: break-word;
    }
    .inbox-viewer__sidebar {
      flex: 5;
      max-width: 2000px;
    }
  }
  .list-item--collapsed {
    &--active {
      background-color: $color--accent;
      .text {
        color: $color--accent--lightest;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: $color--accent--light;
      .text {
        color: $color--accent--lightest;
      }
    }
  }
}
