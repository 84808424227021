@import '~assets/styles/variables';

.breadcrumbs {
  margin-left: -0.5rem;
  margin-right: -0.75rem;
  margin-bottom: 0.25rem;
}

.chevron {
  color: $color--gray;
  display: inline;
  margin-left: -0.25rem;
  margin-right: 0.5rem;

  svg {
    font-size: 11px !important;
  }
}
