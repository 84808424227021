@import '~assets/styles/variables';

$breakpoint--list-item: 750px;

.list {
  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: $border--small;
    min-height: 80px;
    @media screen and (max-width: $breakpoint--list-item) {
      flex-flow: column;
    }
    &__primary,
    &__secondary {
      flex: 1;
      @media screen and (max-width: $breakpoint--list-item) {
        margin-top: 2rem;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;
      &__tooltip {
        opacity: 0;
        position: absolute;
        top: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1em;
        width: 275px;
        height: 4ch;
        background: $color--background--dark;
        border-radius: $border-radius--medium;
        box-shadow: $shadow--medium;
        @include transition-in(opacity, 200ms);
        &__text {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &:hover &__tooltip {
        opacity: 1;
        &::before {
          position: absolute;
          z-index: -1;
          content: '';
          top: -0.6em;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          height: 16px;
          width: 16px;
          transform: rotate(45deg);
          background: $color--background--dark;
          border-top-left-radius: $border-radius--medium;
          box-shadow: $shadow--medium;
        }
        &::after {
          position: absolute;
          z-index: 1;
          content: '';
          top: -0.6em;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          height: 16px;
          width: 16px;
          transform: rotate(45deg);
          background: $color--background--dark;
          border-top-left-radius: $border-radius--medium;
        }
      }
    }
    &__chart {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      @include layout--medium {
        flex-flow: column;
        align-items: flex-start;
      }
      &__container {
        position: relative;
        width: 200px;
        height: 12px;
        background: $color--accent--lightest;
        border: $border--small;
        border-radius: $border-radius--medium;
        margin-right: 1rem;
      }
      &__fill {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $color--accent;
        border-top-left-radius: $border-radius--medium;
        border-bottom-left-radius: $border-radius--medium;
      }
    }
    &__chip {
      display: inline-block;
      text-align: center;
      padding: 0 1rem;
      margin-top: 1rem;
      background-color: $color--brand;
      border-radius: $border-radius--large;
      &__text {
        color: $color--white !important;
      }
    }
    &__button,
    &__chevron {
      margin: auto 0;

      @media screen and (max-width: $breakpoint--list-item) {
        margin-top: 2rem;
        margin-left: auto;
      }
    }
  }
  &__secondary {
    display: flex;
    &__icon {
      color: $color--primary--light !important;
      margin: 2rem 0.75rem 0;
    }
    &__pane {
      justify-content: center;
      width: 100px;
      background-color: $color--background--dark;
      border-right: $border--small;
    }
    &__container {
      width: 100%;
    }
    &__item {
      background-color: $color--background;
    }
  }
}
