@import '~assets/styles/variables';

.container {
  max-width: 580px;
  display: flow-root;
  padding-top: 2rem;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: -1rem;

  span {
    margin-right: 2rem;
    color: $palette--heading;

    strong {
      font-weight: 700;
      color: $palette--text;
    }
  }
}
