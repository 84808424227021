@import '~assets/styles/variables';

.container {
  position: relative;
  padding-top: 1.5rem;

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    top: 0;
    left: -0.25rem;
    right: -0.25rem;
    background-color: $color--gray--light;
  }
}

.toggle {
  display: inline-flex;
  flex-direction: row;
}

.buttonLeft {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.buttonRight {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
