@import '~assets/styles/variables';

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-gap: 2em;
  @media #{$breakpoint--sm} {
    grid-template-columns: 100%;
    margin: 0 1em;
  }
}
