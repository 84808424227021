@import '~assets/styles/variables';

.option {
  padding: 0.5rem 1rem;
  color: $color--gray--darkest;
  &:hover {
    cursor: pointer;
    color: $color--primary;
    background-color: $color--primary--lightest;
  }
}
