@import '~assets/styles/variables';

.toggle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: $spacing;
  :global .md-btn {
    margin-left: 1em;
  }
}

.list {
  margin-bottom: 2em;
}

.button {
  margin-left: 1em;
}
