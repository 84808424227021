@import '~assets/styles/variables';

.container {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.value {
  @extend %font__display;
}

.label {
  font-weight: 700;
  text-transform: uppercase;
}
