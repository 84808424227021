@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.response-container {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color--background--dark;
    border: 1px solid $color--gray--light;
    box-shadow: $shadow--small;
    position: relative;
  }
  &__topbar {
    flex-shrink: 0;
    padding: 2.188rem 2.188rem 1.563rem;
    position: sticky;
    top: - 1rem;
    background-color: $color--white;
    width: 100%;
    border-bottom: 1px solid $color--gray--light;
    box-shadow: $shadow--medium;
    height: 170px;
    display: flex;
    justify-content: space-between;
    &__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
    }
    &__filter-text {
      color: $color--accent;
      text-decoration: underline;
      font-size: 15px;
      cursor: pointer;
    }
  }
  &__card {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: $border--small;
    border-radius: $border-radius--medium;
    width: calc(100% - 60px);
    margin: 1.875rem 0 0 0;
    min-height: 150px;
    &--last {
      margin-bottom: 1.875rem;
    }
    &__question {
      display: flex;
      justify-content: space-between;
      border-radius: $border-radius--medium $border-radius--medium 0 0;
      padding: 2.188rem;
      min-height: 75px;
      background-color: $color--background;
      div {
        max-width: 870px;
      }
      &--archived {
        font-weight: 100;
      }
      &__turf-filter-text {
        color: $color--accent;
        text-decoration: underline;
        font-size: 15px;
        cursor: pointer;
      }
    }
    &__answer {
      border-radius: 0 0 4px 4px;
      padding: 2.188rem;
      border-top: 1px solid $color--gray--light;
      min-height: 75px;
      background-color: $color--white;
      div {
        max-width: 870px;
      }
    }
  }
  &__response-num {
    margin: 0 15px;
  }
}
