@import '~assets/styles/variables';

.menu {
  position: fixed;
  opacity: 1;
  user-select: none;
  background-color: $color--white;
  box-sizing: border-box;
  box-shadow: $box-shadow--card, 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.5rem 0;
  min-width: 200px;
  z-index: 10;
  border-radius: 3px;
}
