.map {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  min-height: 550px;
  :global {
    .mapboxgl-canvas:focus {
      outline: none;
    }
  }
}
