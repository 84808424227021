@import '~assets/styles/variables';

.subheader {
  &__main {
    padding: $spacing--medium;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: $border--small;
    box-shadow: $shadow--small;
  }
  &__details {
    max-height: 0;
    overflow: hidden;
    @include transition-out(all, 100ms);
    &--expanded {
      max-height: 1000px;
      @include transition-in(all, 200ms);
    }
  }
  &__radios {
    display: flex;
    flex-flow: row !important;
    margin-right: 1rem;
  }
}

.grid {
  display: grid;
  grid-gap: 4vw;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  @include layout--medium {
    grid-template-columns: none;
  }
  margin: 4rem;
}

.coversheet {
  &__container {
    text-align: center;
    margin: 4rem 2rem;
    border: $border--small;
    box-shadow: shadow--small;
    :global {
      .react-pdf__Page__canvas {
        box-shadow: none;
      }
    }
  }
}

.compare {
  &__container {
    display: grid;
    grid-template-columns: 45vw 45vw;
    justify-content: center;
    min-height: 500px;
    padding: $spacing--medium;
    @media #{$breakpoint--md} {
      grid-template-columns: 1fr;
    }
  }
  &__carousel {
    display: grid !important;
    grid-template-columns: auto 25% 25% 25% auto;
    grid-gap: 1rem;
    align-items: center;
    justify-items: center;
    &__item {
      display: flex !important;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
      min-height: calc(16vw + 72px);
      min-width: 22vw;
    }
  }
}
