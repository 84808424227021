@import '~assets/styles/variables';

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
}

.bubble {
  margin: 0.35em;
  height: 16px;
  width: 16px;
  background-color: $palette--bg--secondary;
  border-radius: 50%;
  border: $rule;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  > div {
    margin-top: 1px;
  }
  &--active {
    border: $rule;
    background-color: $palette--accent;
    color: #fff;
  }
}
svg.icon {
  margin-top: 0.5em;
  &--rejected {
    @extend .icon;
    color: $palette--warn;
  }
  &--approved {
    @extend .icon;
    color: $palette--success;
  }
}
