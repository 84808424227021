@import '~assets/styles/variables';

.item {
  display: flex;
  flex-flow: row wrap;
  @extend %font__paragraph;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  width: 100%;
  border-bottom: $rule;

  &:last-child:not(.border) {
    border-bottom: none;
  }

  @media #{$breakpoint--sm} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.label {
  padding: 1em 0;
  min-width: 100px;
}

.value {
  display: flex;
  align-items: center;
  text-align: right;
  & > .md-icon-separator {
    padding: 1em;
  }
}
