@import '~assets/styles/variables';

.tick-label {
  font-size: 0.8rem;
}

.legend {
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.95rem;
  &__item {
    display: flex;
    align-items: center;
  }
  &__icon {
    display: flex;
    margin-right: 4px;
  }
}
