@import '~assets/styles/variables';

.toolbar {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  & > * {
    flex: 1;
  }
  p {
    color: $palette--text--secondary;
    text-align: center;
  }
}

.full-width-block {
  margin: 1rem 0;
  min-width: 100%;
}
