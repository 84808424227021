@import '~assets/styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  .name {
    @extend %font__paragraph;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 4;
  }
  .selected {
    font-weight: bold;
  }
  .input {
    flex: 1;
  }
}
