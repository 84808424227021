@import '~assets/styles/variables';

.strong {
  font-weight: 600;
  padding: 0 3px;
}

.list {
  background-color: $palette--bg;
  margin-top: 2rem;
  padding: 1rem;
  ul {
    margin: 0;
    padding-left: 2rem;
  }
  li {
    @extend %font__paragraph;
    padding: 0.5rem 0;
  }
}
