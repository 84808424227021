@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.script-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 1em;
  background: $color--background;
  border-radius: 4px;
  border: 3px solid #eee;
  h4 {
    @extend %font__h4;
  }
  &__container {
    margin: $spacing;
  }
  &__section {
    align-items: center;
    margin: 2em 0 1.5em;
    border-bottom: thin solid $color--gray--light;
    &__header {
      align-self: flex-end;
      margin: 1em 0;
    }
  }
  &__icon {
    padding: 1.2rem 0 1.2rem 1.2rem;
    align-self: flex-start;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    &--accent {
      color: $color--accent;
    }
    & svg:not(:only-child) {
      margin-right: 1rem;
    }
  }
  &__content {
    flex: 1;
  }
  &__name-and-tag {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: $spacing--medium;
    &__field {
      width: 30vw !important;
    }
    & > * {
      margin-right: 4rem;
    }
  }
  &__choices {
    margin: $spacing;
    &__label {
      @extend .muted;
      margin-top: 2em;
    }
    &--edit {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      margin-bottom: 1rem;
      & > svg {
        height: 1.8em;
        width: 1.8em;
      }
      & > button {
        margin-left: 2em;
      }
      &__field {
        margin-bottom: 0 !important;
      }
    }
  }
  &__wrapper {
    display: flex;
  }
  &__actions,
  &__reorder-arrows {
    display: none;
  }
  &__container--edit {
    .script-item {
      &__wrapper:hover .script-item {
        border: 3px dashed rgba(0, 0, 0, 0.12);
      }
      &__actions {
        display: flex;
        padding: 0 1em;
        margin-left: auto;
        align-items: center;
      }
      &__reorder-arrows {
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 0 1em;
      }
    }
  }
}

.button {
  margin: 0.25rem 0.5rem;
}
