@import '~assets/styles/variables';

.bar {
  position: relative;
  display: flex;
  position: relative;
  align-items: stretch;
  border-radius: 3px;
  overflow: hidden;
}

.input {
  padding: 0.75rem 1.25rem 0.75rem 3.5rem;
  border-radius: 3px;
  appearance: none;
  display: block;
  width: 100%;
  border: 1px solid transparent;
  background-color: transparent;
  color: $color--gray--darkest;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none;

    ~ .wrapper {
      border-color: rgba(0, 0, 0, 0.24);
    }

    ~ .icon {
      color: darken($color--accent, 10%);
    }
  }
}

.icon {
  padding-left: 1.25rem;
  color: rgba(0, 0, 0, 0.24);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  transition: all ease-in-out 150ms;

  svg {
    font-size: 18px !important;
  }
}

.button {
  padding-right: 4px;
}

.icon,
.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid $color--gray--light;
  transition: all ease-in-out 150ms;
  border-radius: 3px;
  background-color: $color--white;
  z-index: -1;
}
