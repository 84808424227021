.modal {
  max-width: 40vw;
}

.text {
  padding: 0 0 1.5rem;
}

.field {
  padding-bottom: 1rem;
}
