@import '~assets/styles/variables';

.header {
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
  h2 {
    @extend %font__h4;
    color: $palette--primary;
    margin-right: 80px;
  }
}
