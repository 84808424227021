.buttonBlock {
  display: flex;
  margin: 1rem 0rem;
  & > *:not(:first-child) {
    margin: 0rem 0.5rem;
  }
  &__hint {
    max-width: 200px;
  }
}
