@import '~assets/styles/variables';

.results {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  padding: 1rem;
}

.loadbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
