.chart {
  &__container {
    overflow: hidden;
    background: $palette--bg;
    border: $rule;
    box-shadow: $box-shadow;
  }
  &__toolbar {
    background-color: #fff;
    border-bottom: $rule;
    padding: 1px;
  }
  &__responsive {
    height: 350px;
    width: 100%;
    border-bottom: $rule;
    padding: $spacing;
  }
  &__progress-bars {
    width: 100%;
    display: grid;
    background: $palette--bg;
    &.--sub-group {
      padding-left: 35px;
      background: darken($palette--bg, 2%);
      max-height: 0;
      overflow-y: auto;
      transition: max-height 150ms ease-out, border-color 150ms ease-out;
      border-color: #fff;
      &.--expanded {
        max-height: 180px;
        padding-top: 1em;
        padding-bottom: 1em;
        border-bottom: $rule;
        border-width: 1px;
      }
    }
  }
  &--donut {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: $spacing;
    &__label {
      @extend %font__h4;
      text-align: center;
      &--small {
        @extend %font__small;
        max-width: 200px;
      }
    }
  }
}
