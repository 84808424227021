@import '~assets/styles/variables';

.cell {
  padding: $spacing;
  text-transform: capitalize;

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}
