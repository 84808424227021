@import 'app/assets/styles/variables.scss';
@import 'app/assets/styles/partials/helpers.scss';

.error {
  margin-top: 10px;
}

.stepper {
  display: block;
  &--horizontal {
    &__header {
      display: flex;
      list-style: none;
      margin-top: 0;
      padding: $spacing--medium;
      border-bottom: $border--small;
      box-shadow: 0 0 2px $color--gray--light;
      display: flex;
      justify-content: space-between;
      overflow: scroll;
    }
    .step {
      &__content {
        padding: inherit;
        margin-left: 0;
        border-left: none;
        & > .button__group:not(.button__group--right) {
          margin-bottom: 0;
          flex-flow: row-reverse;
        }
        @include layout--small {
          padding: 1em;
        }
      }
      &__indicator {
        flex-grow: 1;
        padding: 0 2em;
        &::after {
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          background-color: $color--gray--light;
          margin: 0 3em;
        }
        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
      &__body {
        border-left: none;
        margin-left: 0;
        padding-top: 0;
      }
    }
  }
}
.step {
  &__indicator {
    display: flex;
    align-items: center;
    padding: 0;
    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background-color: $color--gray--dark;
      color: $color--white;
      fill: $color--white;
      border-radius: 25px;
      margin: 1em;
      font-weight: 700;
    }
    &--highlight &__circle {
      background-color: $color--primary;
      color: $color--white;
      fill: $color--white;
    }
    &--highlight &__label {
      font-weight: 700;
      white-space: nowrap;
    }
  }
  &__body {
    border-left: $border--small;
    margin-left: 25px;
    padding: 2em 0;
  }
  &__content {
    padding: $spacing--medium;
    padding-bottom: 0;
    padding-top: 0;
  }
}
