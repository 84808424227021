@import '~assets/styles/variables';

.name {
  @extend %font__display;
  padding: $spacing;
  margin-bottom: 1rem;
}

.container {
  display: grid;
  grid-row-gap: 2em;
  grid-template-columns: 2fr 1fr;
  @media #{$breakpoint--md} {
    grid-template-columns: 1fr;
  }
}
