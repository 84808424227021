@import '~assets/styles/variables';

.instructions {
  margin-top: 0;
}

.waitTime {
  display: flex;
  justify-content: space-between;
}

.reconnectionMessage {
  color: $color--accent--dark;
}
