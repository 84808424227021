@import '~assets/styles/variables';

.track {
  background-color: $color--primary--lightest;
  border: $border--small;
  border-color: $color--accent;
  border-radius: $border-radius--medium;
  width: 100%;
  height: 1rem;
  cursor: pointer;
  &:hover {
    box-shadow: $shadow--small;
  }
}

.progress {
  background-color: $color--accent;
  border-radius: $border-radius--medium;
  height: 100%;
  position: relative;
  left: 0;
  transition: width 350ms;
}
