@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.session {
  display: grid;
  grid-template-columns: 4fr 8fr;
  height: 100%;
  overflow: scroll;
  @media #{$breakpoint--sm} {
    height: auto;
  }
  @media #{$breakpoint--sm} {
    grid-template-columns: 1fr;
    height: auto;
  }
}

.sidebar {
  display: flex;
  flex-flow: column;
  overflow: visible;
  :global .react-pdf__Document {
    margin: 1rem;
  }
}

.controls {
  box-shadow: $box-shadow--menu;
  background-color: white;
  border-top: $rule;
  overflow: hidden;
  position: sticky;
  z-index: 2;
  bottom: 0;
  display: flex;
  flex-direction: column;
  @include layout--small {
    position: relative;
  }
  .dialer {
    margin-right: 1rem;
    flex: 1;
    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      @include layout--small {
        flex-flow: column;
      }
    }
    &__error-card {
      flex: 100%;
    }
  }
  .footer {
    position: sticky;
    bottom: 0;
    box-shadow: $box-shadow--menu;
    border: $rule;
    background-color: $palette--footer-bg;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    display: flex;
    @include layout--small {
      flex-flow: column;
      align-items: center;
    }
    &__pagination {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.scan-viewer-header {
  padding: $spacing;
  &__title {
    @extend %font__h4;
  }
  &__aside {
    @extend .hint-text;
  }
}

.notice {
  background-color: $color--accent--lightest;
  box-shadow: $box-shadow--drawer--right;
  border-left: $border--small;
  padding: $spacing--medium;
}
