@import '~assets/styles/variables';

.table {
  width: 100%;
  @extend %font__paragraph;

  &__header {
    padding: 1rem 2rem;
    border-bottom: $rule;
    text-transform: uppercase;
    color: $palette--text--secondary;
    opacity: 0.6;
    font-weight: 400;
    text-align: left;

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }
}
