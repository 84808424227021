.modal {
  min-width: 20vw;
}

.modal-header {
  text-transform: capitalize;
}

.image {
  width: auto;
  max-height: 180px;
}
