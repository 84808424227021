@import '~assets/styles/variables';
$filter-header-padding: 16px 24px;
$filter-body-padding: 1rem 2rem;

.table-filters {
  &__wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    position: relative;
    outline: $border;
    background-color: $palette--header-bg;
  }
  &__header {
    padding: $filter-header-padding;
    &__actions {
      width: 100%;
      @extend %row--spread;
      &--left {
        display: flex;
        justify-content: flex-start;
        padding-left: 1rem;
      }
      &--right {
        display: flex;
        justify-content: flex-end;
        padding-right: 1.5rem;
        flex: 1;
        > * {
          margin: 1rem 0.5rem 1rem 0;
        }
      }
    }
    &__warning {
      display: inline-flex;
      align-items: center;
      height: 32px;
      margin-left: 16px;
      color: $palette--text--secondary;
      font-weight: 700;
      .md-icon {
        margin-right: 4px;
        color: $palette--warn;
      }
    }
  }
  &__body {
    &__wraper {
      overflow: hidden;
    }
    box-sizing: border-box;
    background-color: #fcfcfc;
    display: flex;
    position: relative;
    flex-direction: column;
    border-top: $border;
    &__section {
      padding-top: 6px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
    &__buttons {
      padding-bottom: 1rem;
    }
  }
  &__search {
    width: 200px;
    &__clear {
      border-radius: 50%;
      &:hover {
        cursor: pointer;
        background-color: #eee;
      }
      &:focus {
        background-color: #eee;
        outline: none;
      }
    }
  }
  &__column-list {
    width: 350px;
    padding: 0;
    max-height: 200px;
    &__header,
    &__footer {
      background-color: $palette--header-bg;
    }
    &__header {
      padding: 16px 10px 6px;
      .table-filters__helper-text {
        margin: 0;
      }
    }
    &__footer {
      position: sticky;
      padding: 10px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 0.5rem;
    }
    &__checkbox {
      margin: 0.5rem 1rem;
    }
    &__text {
      font-size: 13px;
      font-weight: 600;
      color: $palette--heading;
      text-transform: uppercase;
    }
    &__item {
      padding-left: 7px;
    }
  }
  &__helper-text {
    font-size: 13px;
    font-weight: 600;
    color: $palette--text--secondary;
  }
  &__controls {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  &__button {
    background-color: #f1f1f1;
    color: $palette--text--secondary;
    &.column-name-btn {
      text-transform: none;
    }
    &.add-rule,
    &.rule-button,
    &.conjunction-button,
    &.column-name-btn {
      margin: 0 16px 16px 0;
    }
    &.rule-button {
      & .md-icon-text {
        text-transform: none;
        font-weight: 600;
      }
    }
    &.open {
      margin-top: 0px;
    }
    &--reset {
      position: absolute;
      top: $filter-body-padding;
      right: $filter-body-padding;
      display: flex;
      margin-right: 16px;
    }
  }
  &__param-input {
    padding: 0 16px;
    &.number {
      width: 50%;
    }
    &.date {
      & input[type='date'] {
        line-height: inherit;
      }
    }
  }
  &__modal {
    width: 600px;
    &__button {
      &--warn {
        background: $color--negative--light;
        color: $color--white;
      }
    }
  }
}
.slide-enter {
  height: 0px;
  &.slide-enter-active {
    height: 300px;
    transition: 150ms ease-out;
  }
}
.slide-leave {
  height: 300px;
  &.slide-leave-active {
    height: 0px;
    transition: 150ms ease-out;
  }
}
