@import '~assets/styles/variables';

.summary__row {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  border-left: none;
  border-right: none;
  padding: 1rem 0;
  @include layout--small {
    flex-flow: column;
  }
}

.positive {
  color: $color--positive !important;
}

.negative {
  color: $color--negative !important;
}
