@import '~assets/styles/variables';

.phone-number {
  &__container {
    display: flex;
    align-items: center;
  }

  &__extension {
    margin-left: 1rem;
  }

  &__text--invalid {
    text-decoration: line-through;
  }
  &__icon--invalid {
    color: $color--negative;
    text-decoration: line-through;
  }
}
