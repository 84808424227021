@import '~assets/styles/variables';

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 20vh;
}

.title {
  @extend %font__h2;
}

.subtitle {
  @extend %font__paragraph;
  color: $palette--text--secondary;
  display: block;
  margin-top: 0.75rem;
  text-align: center;
}

.progress {
  max-width: 500px;
  width: 100%;
}
