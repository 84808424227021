@import '~assets/styles/variables';

.primary {
  color: $color--primary;
}

.accent {
  color: $color--accent;
}

.secondary {
  color: $color--gray--dark;
}
