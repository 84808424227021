@import '~assets/styles/variables';

.placeholderText {
  color: $palette--text--secondary;
  &:before {
    content: '[';
  }
  &:after {
    content: ']';
  }
}
