@import '~assets/styles/variables';

.grid {
  width: 100%;
  @include gridTwoBy();

  &--four {
    @include gridFourBy();
  }
}
