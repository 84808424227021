@import '~assets/styles/variables';

.tree {
  position: relative;
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  margin-bottom: -0.5rem;

  &--root {
    padding: 0;

    > .line {
      display: none;
    }
  }
}

.line {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  top: 0.5rem;
  left: 0.5rem;
  border-left: 1px dashed $color--gray--light;

  &--active {
    border-left-color: $color--accent;
  }
}
