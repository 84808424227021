@import '~assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  border-bottom: $rule;
}

.button {
  display: flex;
  align-items: center;
  padding: 1em;
  .label {
    padding-left: 1em;
    @extend %uppercase;
  }
}
