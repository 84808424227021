@import '~assets/styles/variables';

.marker {
  &__four-plus {
    color: $color--negative !important;
  }

  &__two-to-four {
    color: $color--warn !important;
  }

  &__one-to-two {
    color: $color--primary !important;
  }

  &__less-than-one {
    color: $color--accent !important;
  }

  &__no-shifts {
    color: $color--background--darkest !important;
  }
}

.container {
  cursor: pointer;
  position: relative;

  svg {
    font-size: 32px !important;
    stroke-width: 32px !important;
    stroke: $color--primary !important;
    filter: drop-shadow(0px 1px 1px $palette--gray--light);
  }
}
