@import '~assets/styles/variables';

.number-of-shifts {
  padding-bottom: 1rem;
}

.spacer {
  height: 1rem;
}

.all-time-dropdown {
  width: 100%;
  background-color: $color--accent--dark;
  color: $color--background;
  & > * {
    padding: 1rem 1.5rem;
  }
  &--button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * {
      color: $color--background !important;
    }
    cursor: pointer;
  }
  &--container {
    border-top: 1px solid #dae0e2;
    & > * {
      color: $color--background !important;
    }
  }
  &--icon {
    @include transition-in;
    &--active {
      transform: rotate(90deg);
    }
  }
}

.location {
  padding: 1rem 1.5rem;
  border-bottom: $rule--full;
  background-color: $color--white;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $palette--accent;
    transition: all ease-in-out 0.125s;
  }

  &--active {
    background-color: $color--accent;
    &::after {
      width: 3px;
    }
  }
}

div.location--active > * {
  color: $color--background !important;
}

.title {
  font-weight: 600;
  color: $palette--heading;
  line-height: 1.4;
  font-size: 16px;
}

.section {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    @extend %font__paragraph;
    display: block;
    color: $palette--text;

    &.label {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
      font-family: $font-family--display;
      color: $palette--text--disabled;
    }
  }
}
