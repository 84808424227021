@import '~assets/styles/variables';

.container {
  position: relative;
  background-color: $color--white;

  .loadbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .download {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: $palette--gray--light;
  border-width: 1px 1px 0;
  border-style: solid;
  padding: 0.75rem 1rem 0.75rem 2rem;

  &--withFilters {
    padding-bottom: 0;
  }
}

.filters {
  border-color: $palette--gray--light;
  border-width: 0 1px;
  border-style: solid;
}

.controls {
  display: flex;
  align-items: center;

  .select {
    width: 140px;
  }

  .navigation {
    margin-left: 2rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;

    & > button {
      margin-left: 0.5rem;
    }
  }
}

.calendar {
  position: relative;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($palette--bg, 0.75);
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}
