@import '~assets/styles/variables';

.container {
  position: relative;
}

.button {
  border-radius: 50%;
  height: 2.6rem;
  width: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.5rem;
  text-transform: uppercase;
  background-color: $color--white;
  font-size: 12px;
  font-weight: 600;
  appearance: none;
  border: $rule--full;
  display: flex;
  align-items: center;
  letter-spacing: 1px;

  svg {
    color: $palette--text--disabled;
  }
}

.options {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
}

.option {
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  color: $palette--text--secondary;
  transition: all 175ms ease-in-out;
  white-space: nowrap;

  svg {
    font-size: 13px !important;
    color: $palette--accent;
    opacity: 0;
  }

  &:focus,
  &:hover {
    color: $palette--text;
  }

  &--active {
    svg {
      opacity: 1;
    }
  }
}

.dropdown {
  left: 3.25rem;
  bottom: 0.5rem;
  min-width: 0;
}
