.form-viewer {
  margin-top: 3em;
  li {
    padding: 0;
  }
  &__container {
    display: grid;
    height: 70vh;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 100%;
    border-top: $rule;
  }
  &__title {
    color: $palette--text--secondary;
    font-weight: 700;
    @extend .uppercase;
    padding: 0.25em 1em;
  }
  &__blank-slate {
    background-color: $palette--bg;
    padding: 2em 2em 1em;
  }
  &__sidebar,
  &__packet-content {
    border: $rule;
    overflow: scroll;
    will-change: scroll-position;
    padding-right: 1px;
    &__header {
      background: white;
      position: sticky;
      top: 0;
      z-index: 2;
      border-bottom: $rule;
      box-shadow: $box-shadow;
      &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: $spacing;
      }
      .md-btn {
        @extend %row--spread;
        margin-left: auto;
      }
      .scroll-list__item--secondary {
        text-align: right;
      }
    }
  }
  &__toolbar {
    background: white;
    border-bottom: $rule;
    box-shadow: $box-shadow;
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;
    &__container {
      @extend %row--spread;
      padding: 0 0.25em;
      min-height: 50px;
      & > .md-btn {
        border-radius: 0;
        padding-bottom: 0.25em;
      }
    }
  }
  &__search {
    .md-icon {
      margin: 0.5em 0 0.5em 0.5em;
    }
  }
  .scroll-list {
    &__item {
      border-bottom: $rule;
      padding: 1em;
      &--active {
        background: $palette--secondary;
        color: white;
      }
      &--primary {
        font-size: 1.2rem;
        font-weight: 700;
      }
      &--secondary {
        font-size: 1rem;
        margin-top: 0.5em;
      }
    }
  }

  .static-list {
    &__empty {
      height: 70vh;
    }
  }

  .sort-button {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    margin-bottom: 0;
    opacity: 0.25;
    &__group {
      display: flex;
    }
    &--active {
      border-bottom: 2px solid black;
      opacity: 0.54;
    }
    img {
      height: 24px;
    }
  }
  .form-list-item {
    padding: $spacing;
    border-bottom: $rule;
    background-color: $palette--bg;
    &__main {
      @extend %row--spread;
    }
    &__indicators {
      display: flex;
      justify-content: flex-start;
      &--warn {
        .md-icon,
        .md-icon-text {
          color: $palette--text--secondary;
        }
      }
    }
    &__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      align-items: center;
      .button__group {
        justify-self: flex-end;
        grid-column-end: -1;
      }
    }
    &__notes {
      max-height: 0;
      overflow: hidden;
      transition: max-height 460ms ease-out;
      &--open {
        max-height: 300px !important;
        grid-column: 1/-1;
      }
      &__button {
        padding-left: 0.25em;
        background-color: transparent !important;
      }
      .qc-notes__container {
        background-color: $palette--bg--secondary;
        margin: 1em 0;
        padding: 0.5em 0;
        border: $rule;
        border-radius: 4px;
      }
    }
  }
  .ineligible {
    &__message {
      @extend %row--spread;
      padding: 2em;
      background-color: $palette--bg--secondary;
      border-top: $rule;
    }
    .form-list-item {
      background-color: $palette--bg--secondary;
    }
  }
}
