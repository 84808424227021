@import '~assets/styles/variables';

.hintContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.hint {
  margin: 0.5em;
  max-width: 200px;
  text-align: center;
  @extend %hint-text;
}
