.controls {
  &--inline {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: $spacing;
    @media #{$breakpoint--sm} {
      flex-flow: column;
      align-items: start;
    }
    & > * {
      margin-right: 2em;
      flex: 0 0 auto;
      margin-bottom: 1em;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
  &__label {
    padding: $spacing;
    color: $palette--text--secondary;
  }
  &__drawer {
    border-bottom: $rule;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms ease-in;
    transition: padding 200ms ease-in;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8em;
    &--active {
      overflow: visible;
      padding: 0 4em 4em;
      max-height: 300px;
      transition: max-height 200ms ease-in;
    }
  }
  &__label {
    padding: $spacing;
    color: $palette--text--secondary;
  }
  &__drawer {
    border-bottom: $rule;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms ease-in;
    transition: padding 200ms ease-in;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8em;
    &--active {
      overflow: visible;
      padding: 0 4em 4em;
      max-height: 300px;
      transition: max-height 200ms ease-in;
    }
  }
}
