@import '~assets/styles/variables';

.sheet {
  padding-bottom: 1rem;

  .field > * {
    max-width: 350px;
  }

  .hint {
    @extend %hint-text;
  }
}
