@import '~assets/styles/variables';

.details {
  .options {
    padding-left: 1.5em;
    line-height: 1.5;
    overflow-wrap: anywhere;
    word-wrap: normal;
  }

  em {
    color: $palette--text--secondary;
    background-color: $palette--bg--secondary;
    border-radius: 5px;
    font-size: 10px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    padding: 1px 4px;
    position: relative;
    margin: 0 5px;
  }

  strong {
    padding-right: 0.5rem;
  }
}

.chain {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  padding: 1rem 0;

  > em {
    margin: 6px -1px;
  }

  > span {
    > em {
      top: -2px;
    }
  }
}

.folder {
  display: flex;
  align-items: center;
}

.review-item {
  align-items: flex-start;
}
