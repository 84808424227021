// === react-md overrides and customizations ===
$md-font-family: $font-family--primary;
$md-light-theme: true;
$md-primary-color: $palette--primary;
$md-secondary-color: $palette--accent;
$md-font-icon-separator-padding: 0.5em;
$md-body-desktop-font-size: 16px;
$md-colors-warn-md: false;

@include react-md-everything;
.md-display-1,
.md-headline,
.md-title,
.md-subheading-2,
.md-subheading-1,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family--display;
  color: $palette--heading;
}

body.fixed-header .header {
  z-index: 4;
}

.container {
  padding-right: inherit;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: inherit;
}

.login-container,
.page-container {
  height: 100vh;
}

.profile-dropdown li > a {
  cursor: pointer;
}

.md-grid,
.md-cell {
  margin: 0;
  padding: 0 !important;
}
.md-table-body {
  background: $palette--bg;
}
.md-tooltip {
  border-radius: 4px;
}

.md-icon-separator .md-icon-text {
  color: $palette--text;
}
.md-background--primary,
.md-background--secondary {
  .md-icon-separator .md-icon-text {
    color: white;
  }
}

.md-dialog {
  overflow: auto;
  .md-dialog-content {
    overflow: visible;
  }
}

.md-dialog {
  overflow: auto;
  .md-dialog-content {
    overflow: visible;
  }
}

.md-tabs {
  border-bottom: $rule;
  &-content {
    padding-top: 2rem;
    overflow-y: hidden;
  }
}

.md-tab {
  padding-top: 3em;
  border-bottom: none;
  .md-tab-label {
    color: $palette--text--secondary;
  }
  &-panel {
    padding: 2px;
  }
  &--active {
    .md-tab-label {
      color: $palette--text;
    }
  }
}

.md-btn {
  text-align: center;
}

.md-autocomplete-list {
  width: auto;
}

@media screen and (min-width: 1025px) {
  .md-list--menu-restricted {
    max-height: 200px;
  }
}

.md-progress {
  background: lighten($palette--accent, 20%);
  margin: 0;
  &.margin--top {
    margin-top: 1em;
  }
  &--linear-active {
    background: $palette--accent;
  }
}

.md-menu--select-field {
  .md-text--disabled {
    .md-icon-text {
      color: $palette--text--disabled;
    }
  }
}

.hint-text {
  opacity: 1;
}

.shift-notes {
  margin: 2em 10%;
  color: $palette--text--secondary;
  &__content {
    padding: 2em 0;
    color: $palette--text;
    border-top: $rule;
    @extend .reading-text;
  }
}

.label {
  padding: initial;
  background-color: transparent;
  font-weight: 400;
}

.login-wrapper {
  height: 100vh;
}

[class*=' fa-'],
[class*=' fm-'] {
  color: inherit;
}

.DateRangePickerInput,
.DateInput,
.DateInput_input {
  background-color: transparent;
}
