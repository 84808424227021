@import '~assets/styles/variables';

.filters {
  &__container {
    margin-top: 2rem;
  }
  &__label {
    width: 100%;
    margin: 0 2rem;
    color: $palette--text--secondary;
  }
}
