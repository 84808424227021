@import '~assets/styles/variables';

.carousel {
  padding: 3rem 2rem 2rem;

  .wrapper {
    padding: 1rem 50px 0;
    position: relative;
    text-align: center;
  }

  .dots {
    position: relative;
    padding: 1.5rem 0 1rem;
  }

  .buttons {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .button {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
    }
  }
}
