@import '~assets/styles/variables';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $rule;
  padding: $spacing;
  list-style: none;
  text-transform: uppercase;
  &--child,
  &:hover {
    background-color: $color--background;
  }
  &--active,
  &:focus {
    background-color: lighten($color--accent, 30%);
    outline: none;
  }
  &--active {
    border-bottom: 3px solid $color--accent;
  }
  :global .md-icon-separator {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 200px;
  }
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color--gray--dark;
  &__icon {
    margin-left: 0.5rem;
  }
}

.role-name {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  &__icon {
    color: $color--gray--dark;
  }
}
