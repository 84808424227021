@import '~assets/styles/variables';

.popup {
  :global {
    .mapboxgl-popup-content {
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 0px 2px $palette--gray--light;
      padding: 0;
      font-family: $font-family--primary;
      font-size: 14px;
      font-weight: 400;
    }
    .mapboxgl-popup-tip {
      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $palette--primary;
        border: 2px solid $color--white;
        box-shadow: 0 1px 1px $palette--gray--light;
      }
    }
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    position: absolute;
  }
  &:global {
    &.mapboxgl-popup-anchor-left {
      &:before {
        border-width: 10px;
        border-left: none;
        border-right-color: $palette--gray--light;
        transform: translateY(-50%);
        top: 50%;
        left: -2px;
      }
      .mapboxgl-popup-tip {
        left: 1px;
        position: relative;
        border-right-color: $palette--bg;

        &:after {
          left: -21px;
          top: -8px;
        }
      }
    }
    &.mapboxgl-popup-anchor-top {
      &:before {
        border-width: 11px;
        border-top: none;
        border-bottom-color: $palette--gray--light;
        transform: translateX(-50%);
        top: -3px;
        left: 50%;
      }
      .mapboxgl-popup-tip {
        &:after {
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.mapboxgl-popup-anchor-right {
      &:before {
        border-width: 10px;
        border-right: none;
        border-left-color: $palette--gray--light;
        transform: translateY(-50%);
        top: 50%;
        right: -2px;
      }
      .mapboxgl-popup-tip {
        right: 1px;
        position: relative;
        border-left-color: $palette--bg;

        &:after {
          right: -21px;
          top: -8px;
        }
      }
    }
    &.mapboxgl-popup-anchor-bottom {
      &:before {
        border-width: 11px;
        border-bottom: none;
        border-top-color: $palette--gray--light;
        transform: translateX(-50%);
        bottom: -3px;
        left: 50%;
      }
      .mapboxgl-popup-tip {
        border-top-color: $palette--bg;

        &:after {
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.mapboxgl-popup-anchor-bottom-left {
      &:before {
        border-width: 13px;
        border-left: none;
        border-bottom: none;
        border-top-color: $palette--gray--light;
        bottom: -5px;
        left: -2px;
      }
      .mapboxgl-popup-content {
        border-bottom-left-radius: 0;
      }
      .mapboxgl-popup-tip {
        border-top-color: $palette--bg;

        &:after {
          left: -8px;
          bottom: -20px;
        }
      }
    }
    &.mapboxgl-popup-anchor-bottom-right {
      &:before {
        border-width: 13px;
        border-right: none;
        border-bottom: none;
        border-top-color: $palette--gray--light;
        bottom: -5px;
        right: -2px;
      }
      .mapboxgl-popup-content {
        border-bottom-right-radius: 0;
      }
      .mapboxgl-popup-tip {
        border-top-color: $palette--bg;

        &:after {
          right: -8px;
          bottom: -20px;
        }
      }
    }
    &.mapboxgl-popup-anchor-top-left {
      &:before {
        border-width: 13px;
        border-left: none;
        border-top: none;
        border-bottom-color: $palette--gray--light;
        top: -5px;
        left: -2px;
      }
      .mapboxgl-popup-content {
        border-top-left-radius: 0;
      }
      .mapboxgl-popup-tip {
        &:after {
          left: -8px;
          top: -20px;
        }
      }
    }
    &.mapboxgl-popup-anchor-top-right {
      &:before {
        border-width: 13px;
        border-right: none;
        border-top: none;
        border-bottom-color: $palette--gray--light;
        top: -5px;
        right: -2px;
      }
      .mapboxgl-popup-content {
        border-top-right-radius: 0;
      }
      .mapboxgl-popup-tip {
        &:after {
          right: -8px;
          top: -20px;
        }
      }
    }
  }
  &--empty {
    .header {
      border-bottom: 0;
    }

    &:global {
      &.mapboxgl-popup-anchor-left {
        .mapboxgl-popup-tip {
          border-right-color: $color--white;
        }
      }
      &.mapboxgl-popup-anchor-right {
        .mapboxgl-popup-tip {
          border-left-color: $color--white;
        }
      }
      &.mapboxgl-popup-anchor-bottom,
      &.mapboxgl-popup-anchor-bottom-left,
      &.mapboxgl-popup-anchor-bottom-right {
        .mapboxgl-popup-tip {
          border-top-color: $color--white;
        }
      }
    }
  }
}

.header {
  display: flex;
  min-width: 200px;
  border-bottom: $rule;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    padding: 0.75rem 1.25rem 0.75rem 1rem;
    flex: 1;
    font-weight: 600;
    color: $palette--heading;
    line-height: 1.4;
    font-size: 16px;
  }

  .button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding-right: 1.5rem;

    &:focus {
      outline: none;
    }
  }
}

.content {
  color: $palette--text;
  background-color: $palette--bg;
  padding: 1rem;
}
