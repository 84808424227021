@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.button {
  margin: $spacing;
  margin-left: 0 !important;
}

.disabled-message {
  @extend .hint-text;
}
