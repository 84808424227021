@import '~assets/styles/variables';

.viewer {
  background-color: $color--white;
  outline: none;
  :global {
    .react-pdf__Page__canvas {
      box-shadow: none;
    }
    .react-pdf__Document {
      margin: 2rem;
    }
  }
}

.missing-pledge {
  display: flex;
  flex-flow: column;
  &__container {
    position: relative;
    height: 100%;
    width: auto;
    background-color: $color--background;
  }
  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 2rem;
    width: auto;
    padding: 2rem;
    background-color: $color--gray--light;
    text-align: center;
  }
}
