@import '~assets/styles/variables';

.container {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-gap: 1rem;
  padding: 4rem 2rem;
  background: $color--background--dark;
  margin: 4rem;
  border-radius: 5px;

  h2 {
    @extend %font__h2;
    margin: 0;
    text-align: center;
    color: $color--gray--darkest;
  }

  p {
    @extend %font__paragraph;
    margin: 0;
    text-align: center;
    color: $color--gray--dark;
  }
}
