@import '~assets/styles/variables';

.breadcrumbs {
  &__container {
    display: flex;
    padding: $spacing--medium;
    text-transform: capitalize;
    background-color: $color--background;
    border-bottom: $border--small;
  }
}
