@import '~assets/styles/variables';

.padding {
  padding: $spacing;
  &--sm {
    padding: 0.5em;
  }
}
.text-block {
  color: $palette--text;
  @extend .padding;
  &--bg {
    background: $palette--bg;
  }
}
.align-right {
  text-align: right;
}
.reading-text {
  max-width: 650px;
}
.label {
  color: $palette--text--secondary;
  font-size: 1rem;
}
.pb-label {
  @extend %font__small;
  margin-bottom: 5px;
}
.margin,
.md-cell.margin,
.md-btn--text.margin {
  &-top,
  &--top {
    margin-top: 1em !important;
  }
  &--bottom {
    margin-bottom: 1em !important;
  }
  &--right {
    margin-right: 2em !important;
  }
  &--left {
    margin-left: 2em !important;
  }
}
.headline {
  @extend %font__h2;
}

%row {
  &--spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--wrap {
    display: flex;
    flex-flow: row wrap;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.muted {
  color: $palette--text--secondary;
}

.bold {
  font-family: 'Avenir-Next-Bold';
}

.align-items {
  display: flex;
  align-items: center;
}
.align {
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
}
.filter-type {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 5% !important;
}

.placeholder-text {
  color: $palette--text--secondary;
  &:before {
    content: '[';
  }
  &:after {
    content: ']';
  }
}

.hint-text {
  color: $palette--text--secondary;
  font-size: 13px;
}

.text--warn {
  color: $palette--warn;
}

.md-selection-control-container + .hint-text {
  padding-left: 1em;
}

#listState-menu,
#list-folder-menu {
  margin-left: 0;
  margin-top: 20px;
}

%column {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

%selectable {
  &:hover {
    background-color: $palette--gray--light;
  }
}

// Animation

@mixin quint-ease-in(
  $target: all,
  $duration: 400ms,
  $target2: false,
  $duration2: 400ms
) {
  @if $target2 == false {
    transition: $target $duration $ease-in--quint;
  } @else {
    transition: $target $duration $ease-in--quint,
      $target2 $duration2 $ease-in--quint;
  }
}

@mixin quint-ease-out(
  $target: all,
  $duration: 400ms,
  $target2: false,
  $duration2: 400ms
) {
  @if $target2 == false {
    transition: $target $duration $ease-out--quint;
  } @else {
    transition: $target $duration $ease-out--quint,
      $target2 $duration2 $ease-out--quint;
  }
}

@mixin layout--large {
  @media screen and (max-width: $screen-xlg) {
    @content;
  }
}

@mixin layout--medium {
  @media screen and (max-width: $screen-md) {
    @content;
  }
}

@mixin layout--small {
  @media screen and (max-width: $screen-sm) {
    @content;
  }
}

.space {
  margin: $spacing;
}
