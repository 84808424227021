$ease-in--quint: cubic-bezier(0.22, 1, 0.36, 1);
$ease-out--quint: cubic-bezier(0.83, 0, 0.17, 1);

@keyframes slidein {
  from {
    margin-top: -10px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}
