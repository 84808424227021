.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.avatars {
  margin: 0 1rem;
  display: flex;
  column-gap: 1rem;
}
