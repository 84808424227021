.panel {
  &__header {
    @extend %row--spread;
    @extend %font__h4;
    padding: $spacing;
    border-bottom: $rule;
    .md-icon-text {
      color: $palette--primary;
    }
    &--warn {
      .md-icon-text,
      .md-icon {
        color: $palette--warn;
      }
    }
  }
}
