@import '~assets/styles/variables';

.card {
  margin: 1rem 0 1rem 1rem;
  width: 100%;
  max-width: 300px;
  background-color: $color--background--dark;
  border-radius: $border-radius--medium;
  overflow: hidden;
  box-shadow: $shadow--medium;
  border: $border--small;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 1rem;
  }
  &__preview {
    padding-left: 1rem;
    padding-top: 0;
    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: $color--white;
      border: $border--small;
      border-radius: $border-radius--medium;
      padding: 0.5rem;
    }
  }
}

.animation-container {
  position: relative;
}

.animation-block {
  position: absolute;
  height: 100%;
  width: 100%;
  &--animated {
    background-color: $color--white;
    @keyframes shine {
      0% {
        opacity: 0.6;
        left: 0;
        transition-property: left, opacity;
      }
      100% {
        opacity: 0;
        left: 100%;
      }
    }
    animation: shine 0.9s ease;
  }
}
