.md-btn {
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-content: baseline;
  box-shadow: none;
  font-family: "GT America", sans-serif;
  min-height: 35px;
  text-align: center;
  padding: 0 1em;
  line-height: 3;
  & > *:not(.md-ink-container) {
    margin: auto 0.5em;
  }
  .muted .md-btn--text,
  .md-icon-text {
    color: $palette--text--secondary;
    text-transform: uppercase;
  }
  .svg-inline--fa {
    vertical-align: inherit;
  }
  &--icon {
    flex: 0 1 40px;
    height: 48px;
    width: 48px;
  }
  &--floating {
    flex: 0 1 48px;
    height: 48px;
    width: 48px;
  }
  &--icon {
    padding: 10px;
    &.button--warn:not([disabled]) {
      color: $palette--warn;
      fill: $palette--warn;
    }
    &.md-btn--floating {
      &.button--warn:not([disabled]) {
        background: $palette--warn;
        color: white;
        fill: white;
      }
    }
  }
  &--floating-mini {
    box-shadow: $box-shadow;
    border: $rule;
  }
  &--text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
    @extend %font__btn;
  }
  &--tooltip.md-btn--text {
    overflow: visible;
  }
  &--raised {
    background-color: #cbcbcb;
    &.button--warn:not([disabled]) {
      background-color: $palette--warn;
      border-color: $palette--warn;
      color: white;
    }
    &.md-background--primary {
      background-color: $palette--primary;
      .md-icon-text {
        color: white;
      }
    }
    &.md-background--secondary {
      background-color: $palette--accent;
      .md-icon-text {
        color: white;
      }
    }
  }
  &--flat {
    &.button--warn:not([disabled]) {
      color: $palette--warn;
    }
  }
  &--text,
  .md-icon-text {
    @extend %font__btn;
  }
  &.lowercase {
    .md-btn--text,
    .md-icon-text {
      text-transform: lowercase;
    }
  }
}

.button {
  &__group {
    display: flex;
    .md-btn {
      margin: 0.5em;
    }
    & > .md-icon-separator {
      margin: 0.5em 1em;
    }
    &--right {
      @extend .button__group;
      justify-content: flex-end;
    }
    &--centered {
      @extend .button__group;
      justify-content: center;
      margin: 0 2em;
      .button__group__hint {
        text-align: center;
      }
    }
    &__hint {
      margin: 0.5em;
      max-width: 200px;
      @extend .hint-text;
    }
    .md-icon-separator {
      line-height: 1;
    }
  }
  &__block {
    margin: 1rem;
  }
  &--mini {
    display: flex;
    align-items: center;
    padding: 1em;
    &__label {
      padding-left: 1em;
      @extend .uppercase;
    }
  }
  &--removeable {
    @extend .align-items;
    .md-btn {
      margin-left: 0.5em;
    }
  }
  &--link {
    &:hover {
      color: white;
    }
    &.button--primary {
      @extend .md-btn,
        .md-btn--raised,
        .md-btn--text,
        .md-btn--raised-pressed,
        .md-pointer--hover,
        .md-background--primary,
        .md-background--primary-hover,
        .md-inline-block;
    }
    &.button--accent {
      @extend .md-btn,
        .md-btn--raised,
        .md-btn--text,
        .md-btn--raised-pressed,
        .md-pointer--hover,
        .md-background--secondary,
        .md-background--secondary-hover,
        .md-inline-block;
    }
  }
  &--stacked {
    flex-flow: column;
  }
  &--disabled--fake {
    color: rgba(0, 0, 0, 0.23);
    &:hover {
      background-color: transparent;
    }
  }
  &__hint-container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
