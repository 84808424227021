@import '~assets/styles/variables';

.stats {
  &__container {
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: unset;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
