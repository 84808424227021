.indicator {
  display: flex;
  align-items: center;
  margin-right: 2em;
}

.container {
  display: flex;
  flex-flow: row wrap;
  & > *:not(:last-child) {
    margin-right: 0.5em;
  }
}
