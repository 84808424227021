@import '~assets/styles/variables';

.toggle-box {
  display: flex;
  align-items: center;
  margin: 1em 0;
  &:hover,
  &--selected {
    cursor: pointer;
    .toggle-box__label {
      color: $color--white;
      background-color: $color--primary--light;
      :global {
        svg {
          color: $color--white !important;
        }
      }
    }
  }
  &__group {
    display: flex;
    flex-flow: row wrap;
  }
  &__content {
    border: $border--small;
    box-shadow: $shadow--small;
    border-radius: 4px;
    margin-right: 2em;
    text-align: center;
    width: 250px;
  }
  &__label {
    padding: 2em 1em;
    color: $color--gray--darkest;
    background-color: $color--background;
    font-size: 1.2rem;
    border-bottom: $border--small;
    border-radius: 4px 4px 0px 0px;
    svg {
      font-size: 2.6rem !important;
    }
  }
  &__details {
    background-color: $color--white;
    padding: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
