// Rails table styles to be replaced by react tables later
@include react-md-tooltips;

.table {
  &__container {
    border: $rule;
    border-radius: 2px;
    padding: 1px;
  }
  .truncate {
    position: relative;
    &__text {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__tooltip {
      display: none;
      position: absolute;
      top: 3em;
      padding: 0.5em 1em;
      background: #333;
      opacity: .9;
      font-size: 12px;
      color: white;
      border-radius: 16px;
      @extend %font__paragraph;
    }
    &:hover .truncate__tooltip {
      display: block;
    }
  }
  &--not-selectable .md-table-row--hover {
    background-color: transparent;
    cursor: default;
  }
}

.md-table-row--hover {
  cursor: pointer;
}
