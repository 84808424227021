@import '~assets/styles/variables';

.chart-container {
  min-width: 30vw;
  width: 100vw;
  min-height: 25vh;
  max-height: 60vh;
  margin-right: 2rem;
}

.chart-section-container {
  display: flex;
}
