@import '~assets/styles/variables';

.content {
  border: $rule;
  border-radius: 4px;
  margin: $spacing;
  background: $palette--bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}

.author {
  text-align: right;
  margin: 0 1em;
}
