@import '~assets/styles/variables';

.completed {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  margin-right: 2rem;
  color: $palette--secondary;
  @extend %font__h4;
  width: 60%;
}
.wrapper {
  flex: 2;
  display: flex;
}

.form {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.notes {
  margin: 1rem 1rem 1rem 0;
  width: 50%;
}

.contact-options {
  margin-top: 1rem;
}

.submit-wrapper {
  margin: $spacing;
}

.hint-text {
  margin-top: 1em;
  max-width: 160px;
  @extend %hint-text;
}

.error-card {
  flex: 100%;
}
