@import '~assets/styles/variables';

.chart-container {
  height: 100%;
  width: 100%;
}

:global {
  .recharts-text {
    font-family: $font-family--primary;
  }
}

.reference-line-label {
  transform: translateY(-20px);
  color: red;
}
