.content {
  display: flex;
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--animated {
      @keyframes fade {
        0% {
          opacity: 0;
          transition-property: opacity;
        }
        100% {
          opacity: 1;
        }
      }
      animation: fade 0.6s ease;
    }
  }
}

.button {
  margin: 0.25rem 0.5rem;
}
