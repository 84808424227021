@import '~assets/styles/variables';

.header {
  border-top: $rule--full;
  border-bottom: $rule--full;
}

.wrapper {
  display: flex;
  border-bottom: $rule--full;
  > span {
    margin: 1.5rem 2rem;
    max-width: 33%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.id {
  min-width: 5%;
  @extend %font__h4;
  font-size: 1rem;
  color: $palette--text--secondary;
}

.flagName {
  width: 33%;
  color: $palette--accent;
  text-decoration: underline;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
}
