@import '~assets/styles/variables';

:root {
  --color-danger: #{$color--negative};
  --color-warning: #{$color--warn};
  --color-safe: #{$color--positive};
}

.container {
  position: relative;
  height: 45px;
  border-radius: $border-radius--medium;
  overflow: hidden;
  border: $border--small;
  box-shadow: $shadow--small;
  .guide {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 8px;
    border-bottom: 2px solid $color--white;
    .green {
      width: 100%;
      height: 100%;
      background-color: $color--positive--light;
      flex: 4;
    }
    .yellow {
      width: 100%;
      height: 100%;
      background-color: $color--warn--light;
      flex: 1;
    }
    .red {
      width: 100%;
      height: 100%;
      background-color: $color--negative--light;
      flex: 1;
    }
  }
  .main {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    &--active {
      height: calc(100% - 8px);
      display: inline-block;
      transition: width 1s;
      border-radius: 0 4px 4px 0;
    }
    &--inactive {
      height: 100%;
      transition: width 1s;
      display: inline-block;
      background-color: $color--white;
      opacity: 0.5;
    }
  }
}

.hint {
  margin-top: 0.5rem;
}

.legend {
  margin: auto;
  &__container {
    position: relative;
    top: 1.1em;
    width: 7em;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 0.5em;
  }
  &__item {
    color: $color--gray--darkest;
    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      margin-left: 1em;
    }
    &--safe::after {
      background: $color--positive;
    }
    &--warning::after {
      background: $color--warn;
    }
    &--danger::after {
      background: $color--negative;
    }
  }
}
