@import '~assets/styles/variables';

.viewer {
  :global {
    .md-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing;
      border-bottom: $rule;
      display: contents;
    }
  }
}

.canvas {
  background-color: $palette--bg;
  padding: 5%;
  overflow: scroll;
  img {
    width: 100%;
    height: auto;
  }
}
