@import '~assets/styles/variables';

.label {
  padding: 0.5em 0;
  &--primary {
    color: $color--primary;
  }
  &--accent {
    color: $color--accent;
  }
  &--tight {
    margin: 0;
  }
}
