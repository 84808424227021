.quality-control__scheduler {
  position: relative;
  &__loadbar {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__summary {
    display: flex;
  }
  &__form {
    grid-template-columns: 1fr 0.75fr;
    margin-top: 0;

    @media #{$breakpoint--md} {
      grid-template-columns: 1fr;
    }

    &--modal {
      grid-template-columns: 1fr;
    }

    &__label {
      @extend %font__h4;
      color: $palette--primary;
      border-bottom: $rule;
      padding: $spacing;
    }
  }
}
