@import '~assets/styles/variables';

.item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  &__main {
    flex-grow: 1;
  }
}
