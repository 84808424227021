@import '~assets/styles/variables';

.chart {
  &__container {
    display: flex;
    flex-flow: row wrap;
    @include layout--small {
      flex-direction: column;
    }
  }
  &__line,
  &__gauge {
    display: flex;
    flex-flow: column;
    @include layout--medium {
      flex: none;
      margin: 2rem 0;
    }
  }
  &__line {
    flex: 2;
  }
  &__gauge {
    flex: 1;
  }
}

.field {
  &__date {
    max-width: 300px;
    @include layout--medium {
      flex: none !important;
    }
  }
}
