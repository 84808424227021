@import '~assets/styles/variables';

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-top {
  margin-top: 1rem;
}

.offset {
  margin-top: 1rem;
  margin-left: 1rem;
}

.phone-number--invalid {
  color: $color--negative;
  text-decoration: line-through;
}
