@import '~assets/styles/variables';

.schedule {
  &__layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > *:nth-child(1) {
      flex: 2;
    }
    @media (max-width: $breakpoint--small) {
      flex-direction: column-reverse;
      & > *:nth-child(1) {
        flex: 1;
      }
      & > *:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
}
.day-select {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: $spacing--medium;
    background: $color--background--dark;
    border: $border--small;
  }
  &__button {
    border-radius: 50% !important;
    height: 42px;
    width: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
