@import '~assets/styles/variables';

.cluster {
  cursor: pointer;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  font-weight: 600;
  border-radius: 3px;
  border: 2px solid $color--white;
  box-shadow: 0 1px 1px $palette--gray--light;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color--white;

  &--low {
    background-color: $palette--accent;
  }
  &--medium {
    background-color: #f28a25;
  }
  &--high {
    background-color: #f44336;
  }
}
