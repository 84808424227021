.switch {
  :global .md-switch-container {
    align-items: flex-start;
  }
}

.turf-setting__row {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.manage-button {
  margin-top: 1.5rem;
}
