@import '~assets/styles/variables';

.container {
  padding-bottom: 2em;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  border-top: $rule;
  border-bottom: $rule;

  .content {
    border-bottom: $rule;
    padding-bottom: 2em;
  }
}
