@import '~assets/styles/variables';

.tick-label {
  font-size: 0.9rem;
}

.response-link {
  cursor: pointer;
  fill: $color--primary--light;
  color: $color--primary--light;
  &:hover {
    fill: $color--accent;
    color: $color--accent;
  }
}

.legend {
  padding: 0;
  margin: 0;
  text-align: right;
  &__item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 4rem;
    }
    &--inactive {
      color: $color--gray--light;
    }
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
}
