@import '~assets/styles/variables';

.background {
  display: flex;
  flex-flow: column;
  background-color: $palette--bg--secondary;
  height: 100%;
  border-radius: 0.25rem;
  border: $rule;
  margin: $spacing;
  min-width: 35vw;
  max-width: 35vw;
}

.label {
  padding-bottom: 0;
  padding-left: 1rem;
  text-transform: uppercase;
  @extend %font__small;
}

.editor {
  background-color: $color--white;
  margin: 1rem;
  border-radius: 0.25rem;
  &__button {
    padding: 0.85rem;
    margin: 0.25rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--active {
      background-color: $palette--bg--secondary;
      border-radius: 0.25rem;
      box-shadow: inset 1px 1px 1px;
      border: $rule;
    }
  }
  &__buttons {
    cursor: pointer;
    display: flex;
    border-bottom: $rule;
  }
  &__text-field {
    min-height: 200px;
    padding: 1rem;
    ul,
    ol {
      padding-left: 1.5rem;
    }
  }
  &__link {
    border-bottom: $rule;
    display: flex;
    width: 100%;
    padding: 0.5rem;
    &__field-block {
      margin-left: 0.5rem;
      margin-right: 1rem;
      margin-top: 0;
      margin-bottom: 0;
      :global {
        .md-text-field-container {
          margin: 0;
        }
      }
    }
    &__button-block {
      margin: 0;
    }
  }
}
