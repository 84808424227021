@import '~assets/styles/partials/helpers';

.hint {
  @extend .hint-text;
  margin-left: 2rem;
}

.event-form > * {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.public-event-checkbox-container {
  display: block !important;
}
