@import '~assets/styles/variables';

.panel {
  background-color: $color--white;
  padding: 1rem 0;
}

.header {
  margin: $spacing--medium;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .label {
    color: $color--accent--dark;
  }
}

.endSessionBtn {
  margin-left: 1rem;
}

.ButtonBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0 0;
  padding: 0;
}

.operatorDisconnectErrorMessage {
  color: $color--negative !important;
}
