@import '~assets/styles/variables';

@keyframes flashandhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  flex-flow: row wrap;
  row-gap: 1rem;
}

.link {
  text-decoration: underline;
  max-width: 100%;
  word-wrap: break-word;
}

.button {
  padding: 0 1em;
}

.message {
  animation: flashandhide 600ms 3s;
  animation-fill-mode: forwards;
  position: absolute;
  bottom: -2rem;
  left: 0;
  display: flex;
  align-items: center;
}
