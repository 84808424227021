.toolbar {
  display: flex;
  justify-content: flex-start;
  padding: $spacing;
  align-items: flex-end;
  & > * {
    margin-right: 3em;
  }
  &--flush-top {
    align-items: flex-start;
  }
  @media #{$breakpoint--sm} {
    flex-flow: column;
    align-items: flex-start;
  }
  &__actions {
    display: flex;
    button,
    a {
      margin-left: 1em;
    }
    @media #{$breakpoint--sm} {
      padding: $spacing;
    }
  }
}
