@import '~assets/styles/variables';

.notice {
  grid-column: 1/-1;
  text-align: center;
  padding: 1rem 2rem;
  margin: 0rem 1rem 1rem;
  background: $palette--bg--secondary;
  border-radius: 4px;
}
