@import '~assets/styles/variables';

.paginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  :global .md-btn {
    margin: 0 1em;
  }
  &--justify-left {
    justify-content: flex-start;
  }
  &--justify-center {
    justify-content: center;
  }
}
