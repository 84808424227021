@import '~assets/styles/variables';

.card {
  margin: 2rem 0 1rem 1rem;
  width: 100%;
  max-width: 300px;
  background-color: $color--background--dark;
  border-radius: $border-radius--medium;
  overflow: hidden;
  box-shadow: $shadow--medium;
  &__title {
    background-color: $color--background--dark;
    display: flex;
    padding: 1rem;
  }
  &__content {
    background-color: $color--background--dark;
    padding-left: 1rem;
    padding-top: 0;
    &__container {
      background-color: $color--white;
      border: $border--small;
      border-radius: $border-radius--medium;
      border-radius: 0.25rem;
      width: 100%;
      display: flex;
      align-content: space-between;
      padding: 0.5rem;
    }
    &__color-label {
      width: 100%;
    }
    &__color-input {
      height: 22px;
      cursor: pointer;
      width: 22px;
      border-radius: 4px;
      border: $rule;
      box-shadow: $box-shadow;
      > input {
        visibility: hidden;
      }
    }
  }
}
