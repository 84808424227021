@import '~assets/styles/variables';

.list {
  &__primary {
    display: flex;
    align-items: center;
    column-gap: 4rem;
  }
  &__icon {
    color: $color--primary--light !important;
  }
}
