@import '~assets/styles/variables';

.header {
  position: sticky;
  top: - 1rem;
  z-index: 2;
  background-color: $color--background;
  transition: background-color 0.125s linear;

  &--stuck {
    background-color: #fff;
    border-bottom: $rule;
  }
}

.wrapper {
  padding-top: 1rem;
}

.content {
  display: flex;
  .search {
    flex: 1;
  }
}

.loader {
  position: sticky;
  top: 60px;
}

.action {
  flex: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.title {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  span {
    @extend %font__h2;
    @extend %capitalize;
  }
}

.detail {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: $spacing;

  .label {
    @extend %font__small;
    color: $color--gray--dark;
    margin-bottom: 0;
  }
}
