@import '~assets/styles/variables';

.content {
  width: 100%;
}

.instructions {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  > li {
    max-width: 30%;
  }
}

.loadbar {
  height: 8px;
  margintop: 1em;
}

.label {
  @extend %font__small;
  margin-bottom: 5px;
}
