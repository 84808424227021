.for-phone-bank {
  display: flex;
  justify-content: flex-start;
  column-gap: 0.5rem;
  padding: 0.5rem;
}

.container {
  max-width: 100vw;
}
