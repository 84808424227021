@import '~assets/styles/variables';

.breadcrumb {
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: -0.125rem;
  width: fit-content;
}

.link {
  padding: 0.5rem 0.75rem;
  display: block;
  border-radius: 5px;
  border: 2px solid transparent;
  font-size: 12px;
  font-weight: 600;
  font-family: $font-family--primary;
  line-height: 1.5;
  text-transform: uppercase;
  transition: none !important;

  &:hover {
    background-color: $color--white;
    border: 2px solid $color--gray--light;
  }

  &--active {
    border: 2px solid $color--accent;
    background-color: rgba($color--accent, 0.25);
  }

  &--descendant {
    opacity: 0.45;
  }
}
