@import '~assets/styles/variables';

.modal {
  max-width: 40vw;
}

.text {
  padding: 0 0 1.5rem;
}

.hint {
  @extend %hint-text;
  padding: 0 0 0.5rem;

  &--warn {
    color: $palette--warn;
  }
}

.field {
  padding-bottom: 1rem;
}
