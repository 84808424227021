@import '~assets/styles/variables';

.container {
  position: relative;
  overflow: hidden;
  border-radius: 2px;

  .banner {
    background-color: $palette--bg--secondary;
    border: 0;
  }

  .loadbar {
    background-color: $color--gray--darkest;
    position: absolute;
    top: 0;

    > div {
      background-color: $color--gray--darkest;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    padding: 1rem 2rem;
    color: $palette--text--secondary;
  }
}
