@import '~assets/styles/variables';

.item {
  cursor: pointer;
  position: relative;

  &--disabled {
    cursor: not-allowed;

    > .content {
      color: $palette--text--disabled;

      svg {
        color: lighten($palette--accent, 20%);
        fill: lighten($palette--accent, 20%);
      }
    }
  }

  &:not(&--disabled) {
    .content {
      color: $palette--text;

      svg {
        color: $palette--accent;
        fill: $palette--accent;
      }
    }

    &:focus {
      outline: 0;
    }

    &:hover > .content {
      background-color: $palette--bg;
    }

    &:focus > .content {
      background-color: $palette--bg--secondary;
    }
  }

  .content {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;

    .icon {
      margin-right: 1rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
