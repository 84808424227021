@import '~assets/styles/variables';

.card {
  display: flex;
  flex-flow: column;
  background-color: $color--white;
  margin: $spacing--medium;
  border: $border--small;
  border-radius: $border-radius--small;
  box-shadow: $shadow--small;
  .header {
    display: flex;
    padding: $spacing--medium;
    justify-content: space-around;
    align-items: flex-end;
    text-align: center;
    border-bottom: $border--small;
    color: $color--primary--light;
  }
}
