@import '~assets/styles/variables';

.container {
  padding-top: 1em;
  grid-column: 2/-2;
  display: grid;
  grid-gap: 3em;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  margin-bottom: 1em;
  @media #{$breakpoint--sm} {
    grid-column: 1/-1;
    grid-template-columns: 1fr;
  }
}

.details {
  border: $rule;
  padding: $spacing;
  border-radius: 4px;
}
