@import '~assets/styles/variables';

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 1.75em;
  flex-basis: 250px;
  min-width: 250px;
  & > * {
    max-width: 250px;
    margin: $spacing;
  }
  & > *:not(button):not(i) {
    flex: 1 1 140px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: auto;
  }
  & > i,
  & > button {
    flex: 0 1 40px;
    align-self: center;
    margin-top: auto;
    margin-right: 1em;
    margin-left: 0;
    padding-bottom: 0.25em;
  }
}
