@import '~assets/styles/variables';

.carousel {
  display: grid !important;
  grid-template-columns: auto 25% 25% 25% auto;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  &__item {
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    padding: 1rem;
    height: 100%;
    min-height: calc(16vw + 72px);
    min-width: 22vw;
    background-color: $color--white;
    border: $border--small;
    border-radius: $border-radius--medium;
    box-shadow: $shadow--small;
    &:hover {
      cursor: pointer;
      box-shadow: $shadow--medium;
    }
  }
}
