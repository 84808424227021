.inbox-viewer {
  position: relative;
  display: flex;
  &__main {
    flex: 1;
    max-width: 2000px;
    .list__item__details {
      opacity: 1;
      transition: opacity 200ms ease-in 100ms;
    }
    @include quint-ease-out(max-width);
  }
  &__sidebar {
    flex: 0;
    max-width: 0;
    overflow: hidden;
    border-left: $rule;
    box-shadow: -2px 0 4px $palette--gray--light;
    @include quint-ease-out(max-width);
    &__header {
      height: 88px;
      padding: $spacing;
      text-align: center;
      border-bottom: $rule;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        @extend %font__h2;
      }
      &--full-width {
        @extend .inbox-viewer__sidebar__header;
        position: relative;
        z-index: 2;
        border-top: $rule;
      }
    }
  }
  &__collapse-bar {
    background: $palette--bg--secondary;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 88px;
    padding: $spacing;
  }
  &--open {
    .inbox-viewer__main {
      max-width: 300px;
      word-break: break-word;
    }
    .inbox-viewer__sidebar {
      flex: 5;
      max-width: 2000px;
    }
  }
}
