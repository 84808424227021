@import '~assets/styles/variables';

.group {
  display: flex;
  flex-direction: column;
}

.header {
  border-bottom: $rule--full;
  padding-bottom: 0.5rem;
}

.content {
  display: flex;
}
