@import '~assets/styles/variables';

.panel {
  height: 100%;
  overflow: auto;
}

.container {
  position: relative;
}

.header {
  position: sticky;
  padding: 1.5rem 2rem;
  z-index: 2;
  display: grid;
  grid-gap: 1.5em;
  top: -0.5rem;
  background-color: $color--background;
  box-shadow: none;
  transition: background-color 0.075s ease-in-out, box-shadow 0.075s ease-in-out;

  &--stuck {
    background-color: $color--white;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
  }

  .title {
    @extend %font__h2;
    color: $color--gray--darkest;
  }
}

.loadbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
}

.bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: $color--gray--dark;
  }

  .input {
    margin: 0 1.5rem;
  }
}

.toggle {
  display: inline-flex;
  flex-direction: row;
}

.back {
  margin-left: -0.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: $color--gray;
    font-size: 12px !important;
    margin-right: 0.75rem;
    margin-top: 2.5px;
  }

  a {
    padding: 0.5rem 0.75rem;
    display: inline-flex;
    border-radius: 5px;
    border: 2px solid transparent;
    font-size: 12px;
    font-weight: 600;
    font-family: $font-family--primary;
    line-height: 1.5;
    text-transform: uppercase;
    transition: none !important;
    align-items: flex-start;

    &:hover {
      background-color: $color--white;
      border: 2px solid $color--gray--light;
    }
  }
}
