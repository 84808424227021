@import '~assets/styles/variables';

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  word-break: break-all;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;

  .icon {
    color: $color--accent;

    svg {
      font-size: 36px !important;
    }
  }

  .name {
    color: $color--gray--darkest;
  }

  span {
    text-align: center;
    font-family: $font-family--primary;
    font-size: 14px;
    font-weight: 400;
    margin-top: 1rem;
  }

  &:hover,
  &--focused {
    background-color: $color--white;
    border: 2px solid $color--gray--light;
  }

  &--active {
    border: 2px solid $color--accent;
    background-color: rgba($color--accent, 0.25);
  }

  &--disabled {
    opacity: 0.45;
  }

  &--clipboard {
    border: 2px dashed $color--gray--light;
    border-style: dashed !important;
  }
}

a {
  &:focus > .item {
    background-color: $color--white;
    border: 2px solid $color--gray--light;
  }
}
