@import '~assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: $color--brand;
  box-shadow: $shadow--medium;
}

.message {
  color: $color--white !important;
  text-align: center;
  flex: 1;
}

.clear-button {
  margin-left: auto;
}
