@import '~assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  margin: $spacing--medium--collapsed;
  gap: 1rem;
  &__value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &--error {
      text-decoration: line-through;
      color: $color--negative;
    }
  }
  &__icon {
    color: $color--gray--dark;
    &--error {
      color: $color--negative;
    }
  }
}
