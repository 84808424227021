@import '~assets/styles/variables';

.container {
  display: flex;
  flex-flow: row !important;
  column-gap: 1rem;
  max-width: 300px;
}

.button {
  margin-top: 1.4rem;
}
