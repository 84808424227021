@import '~assets/styles/variables';

.timer {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  &__text {
    color: $color--primary !important;
  }
}
