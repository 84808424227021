@import '~assets/styles/variables';

.container {
  padding: $spacing--medium;
}

.option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  min-width: 250px;
  width: 3%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 1em 1em;
  padding: $spacing--medium;
  background: $color--background--dark;
  box-shadow: $shadow--small;
  color: $color--primary;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    background-color: $color--primary--light;
    border-color: $color--primary--dark;
    color: $color--white;
  }
  &--selected {
    background-color: $color--primary;
    border-color: $color--primary--dark;
    color: $color--white;
  }
  &__icon {
    font-size: 3em;
    margin: 0.5rem;
  }
}
