@import '~assets/styles/variables';

.actions {
  outline: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  > h2 {
    @extend %font__h2;
    padding: $spacing;
  }
}
