@import '~assets/styles/variables';

.dialer {
  display: flex;
  align-items: center;
  gap: 1rem;
  @include layout--small {
    margin-bottom: 1rem;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    @include layout--small {
      flex-flow: column;
    }
  }
}
