@import '~assets/styles/variables';

.paginator {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-left: auto;
  @include layout--small {
    margin: 0;
  }
  &__button {
    border: $border--small;
    background-color: white;
  }
}
