@import '~assets/styles/variables';

.dropzone {
  flex: 1;
  background-color: $color--white;
  transition: background-color 0.12s ease-in-out;
  padding: 1rem 0;

  &--isOver {
    background-color: $palette--bg;
  }

  &--isEmpty {
    padding-top: 0;
  }
}

.placeholder {
  border: 2px dashed $palette--text--disabled;
  color: $palette--text--secondary;
  max-width: 300px;
  text-align: center;
  padding: 1rem;
  margin: 2rem 2rem 1rem;
}
