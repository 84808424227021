@mixin gridTwoBy() {
  display: grid;
  grid-gap: 0 16vw;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  @media #{$breakpoint--md} {
    grid-template-columns: none;
  }
}

@mixin gridFourBy() {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 50px 0;
  box-sizing: border-box;

  @media #{$breakpoint--xlg} {
    grid-template-columns: 33% 33% 33%;
  }

  @media #{$breakpoint--md} {
    grid-template-columns: 50% 50%;
  }

  @media #{$breakpoint--mid} {
    grid-template-columns: 1fr;
  }
}
