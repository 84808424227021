@import '~assets/styles/variables';

.container {
  background-color: $color--white;
  border: 1px solid $palette--gray--light;
  border-radius: 2px;
  width: 100%;

  :global {
    .md-tab-panel {
      padding: 0;
    }
  }
}

.tabs {
  &:global(.md-tabs) {
    padding-left: 2px;
    border-bottom: 0;
  }
}
