.offset {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.phone-label {
  display: inline-flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
}
