@import '~assets/styles/variables';

.button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: baseline;
  &--icon {
    flex: 0 1 40px;
    height: 48px;
    width: 48px;
  }
  &--floating {
    flex: 0 1 48px;
    height: 48px;
    width: 48px;
  }
  &--default {
    &:global.md-btn {
      color: $color--primary;
    }
  }
  & > {
    *:global:not(.md-ink-container) {
      margin: auto 0.5em;
    }
  }
}
