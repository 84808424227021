@import '~assets/styles/variables';

.content {
  border: $rule;
  border-right: none;
  background-color: $color--background;
}

.list {
  padding-left: 0;
  li {
    margin-bottom: 1em;
    list-style-position: inside;
  }
}

.title {
  @extend %font__h2;
  @extend %capitalize;
  margin-top: 1rem;
}
