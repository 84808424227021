@import '~assets/styles/variables';

.header {
  display: flex;
  align-items: center;
  padding-right: 4px;

  .label {
    display: flex;
    align-items: center;
    padding: 1rem;
    flex: 1;
    color: $palette--text;

    span {
      padding: 0 0.75rem;
    }
  }

  .action {
    flex: 0;
    padding: 10px;
  }
}

.container {
  margin: 1rem 2rem;
  display: flex;
}

.card {
  background-color: $color--white;
  width: 300px;
  border: $rule--full;
  box-shadow: $box-shadow--card;
  &--invalid {
    border: 1px solid $palette--warn;
  }
}

.card-error {
  color: $color--negative !important;
  margin-bottom: 2.5em;
}

.actions {
  padding: 5px 0.5rem 1rem;
}

.body {
  padding: 1rem;
  margin: 0 0.5rem 0.5rem;
  background-color: $palette--bg;
  border-top: $rule--full;
  .warning {
    padding-top: 1rem;
    color: $palette--warn;
  }
}

.instructions {
  @extend %hint-text;
  max-width: 150px;
  display: block;
  padding: 0 0.5rem;

  i {
    padding: 0 2px;
    top: 3px;
    position: relative;
    font-size: 16px !important;
  }
}
