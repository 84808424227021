.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.slider-block {
  flex: 2;
}

.button-block {
  flex: 0;
}
