@import '~assets/styles/variables';

.block {
  color: $palette--text;
  padding: $spacing;
  max-width: 750px;
  @extend %font__paragraph;
  &--bg {
    background: $palette--bg;
  }
  &--warn {
    background: $palette--bg;
    color: $palette--warn;
  }
  &--muted {
    color: $palette--text--secondary;
  }
}
