$screen-sm: 667px;
$screen-mid: 850px;
$screen-md: 1024px;
$screen-xlg: 1750px;

$breakpoint--sm: 'screen and (max-width: ' $screen-sm ')' !default;
$breakpoint--mid: 'screen and (max-width: ' $screen-mid ')' !default;
$breakpoint--md: 'screen and (max-width: ' $screen-md ')' !default;
$breakpoint--lg: 'screen and (min-width: ' $screen-md ')' !default;
$breakpoint--xlg: 'screen and (max-width: ' $screen-xlg ')' !default;

$spacing: 1rem 2rem;
@media #{$breakpoint--sm} {
  $spacing: 10px 20px;
}
