@import '~assets/styles/variables';

.callScript {
  background-color: white;
  box-shadow: $box-shadow--drawer--right;
  height: 100%;
  border-left: $rule;
  padding: 2em;
}

.header {
  display: flex;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
}

.languageToggle {
  display: flex;
  justify-self: end;
}

.languageToggleButton {
  margin-left: 1rem;
}

.scriptItemWrapper {
  margin: $spacing;
}
