@import '~assets/styles/variables';

.links {
  display: grid;
  grid-gap: 0.5rem;

  .link {
    text-align: right;
  }
}
