@import '~assets/styles/variables';

.field {
  &__time-picker {
    display: flex !important;
    flex-flow: row !important;
    & > * {
      margin-right: 2em;
    }
  }
  &__preview {
    margin: auto 0 !important;
    padding-bottom: 1.2rem;
  }
}

.prepare {
  display: grid;
  grid-row-gap: 80px;
  margin-bottom: 4em;
  p {
    margin-bottom: 0;
  }
}

.checkbox-group {
  margin: 2em 0;
  &--stacked {
    display: grid;
    grid-template: repeat(auto-fill, 2rem) / repeat(auto-fit, 400px);
    margin: $spacing;
    :global {
      .md-selection-control-container span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
