@import '~assets/styles/variables';

.input {
  margin: $spacing;

  ul {
    margin-bottom: 0;
  }
}

.label {
  color: $palette--text--secondary;
  padding-bottom: 0.5em;
}

.container {
  display: flex;
}

.main {
  max-width: 650px;
  background-color: $palette--bg;
  padding: $spacing;
  border: $rule;
  border-radius: 4px;
  flex: 1;

  :global {
    .md-text-field {
      background: $palette--bg;
    }
  }
}

.controls {
  padding: $spacing;
}
