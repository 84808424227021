@import '~assets/styles/variables';

.header {
  &--deceased {
    margin-bottom: 0;
  }
}

.banner {
  background-color: $color--negative;
  padding: 0.45rem;
  margin-bottom: $spacing--small;
  text-align: center;

  &__text {
    color: $color--white;
  }
}
