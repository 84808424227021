@import '~assets/styles/variables';

.container {
  width: 100%;
}

.field {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin-bottom: 2px;

  &--focused,
  &--has-value {
    .label {
      bottom: 25px;
      font-size: 14px;
      transition: bottom 200ms ease-out;
    }
  }
  &--focused {
    .label {
      color: $color--gray--darkest;
    }
  }
  &--error {
    .label {
      color: $color--negative;
    }
    :global .ReactSelect__control {
      border-bottom: thin solid $color--negative;
    }
  }
}

.label {
  position: absolute;
  bottom: 2px;
  z-index: 1;
  color: $color--gray--dark;
  font-size: 14px;
  font-weight: 400;
  transition: bottom 200ms ease-out;
}

.help {
  @extend %font__small;
  color: $color--gray--dark;
}

.error {
  @extend %font__small;
  color: $color--negative;
}

// React-select overrides
.container,
body {
  :global {
    .ReactSelect {
      &__indicator-separator {
        display: none;
      }

      &__dropdown-indicator {
        padding: 0rem;
      }

      &__control {
        border-radius: 0;
        background-color: transparent !important;
        border: none;
        border-bottom: thin solid $color--gray--light;
        min-height: 36px;
        align-items: flex-end;
        box-shadow: none;
        &:hover {
          border-color: $color--gray--light;
        }
      }

      &__value-container {
        padding: 0;

        &--has-value {
          .ReactSelect__single-value {
            color: $color--gray--darkest;

            &--is-disabled {
              color: $color--gray;
            }
          }
        }
      }

      &__single-value {
        font-size: 13px;
        line-height: 3.5;
        margin-top: 2px;
        color: $color--gray--dark;

        &--is-disabled {
          color: $color--gray;
        }
      }

      &__menu {
        border-radius: 0;
        margin: 0;
        border: $border--small;
        border-bottom-left-radius: $border-radius--medium;
        border-bottom-right-radius: $border-radius--medium;
      }

      &__menu-list {
        padding: 0;
        box-shadow: $shadow--medium;
        border-bottom-left-radius: $border-radius--medium;
        border-bottom-right-radius: $border-radius--medium;
      }

      &__option {
        &:active {
          background-color: $color--background--light;
        }

        &--is-focused {
          background-color: $color--background;

          &:active {
            background-color: $color--background--light;
          }
        }

        &--is-selected {
          color: $color--accent;
          background-color: $color--background;

          &:active {
            background-color: $color--background--light;
          }
        }
      }

      &__placeholder {
        display: none;
      }
    }
  }
}
