.sheet {
  background: #fff;
  border-radius: 2px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  &__edit-dialog {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;
    width: 100%;
    height: 100%;
    &__toolbar {
      display: flex;
      padding: $spacing;
      border-bottom: $rule;
    }
    &__title {
      @extend %font__h1;
      margin-left: 0.5em;
    }
    &__content {
      padding-top: 2em;
    }
  }
  &__edit-button {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
  }
  &__header {
    padding: $spacing;
    color: $palette--text;
    border-bottom: $rule;
    h1 {
      @extend %font__h2;
      color: $palette--primary;
      text-transform: uppercase;
    }
    h4 {
      @extend %font__paragraph;
      margin: 5px 0 0;
    }
    &--primary {
      h1 {
        @extend %font__h1;
        text-transform: uppercase;
        padding: 2rem 2rem 0.5rem;
      }
    }
    &--secondary {
      margin: 0;
      color: $palette--text--secondary;
    }
  }
  &--stacked {
    border-top: none;
    border-radius: 0 0 2px 2px;
  }
  .md-data-table--responsive {
    margin: 1px;
  }
  .dialog--edit {
    padding: 4em 0;
  }
}
