@import '~assets/styles/variables';

.icon {
  color: #abafb3;

  &.word {
    color: #0689d8;
  }
  &.powerpoint {
    color: #e44400;
  }
  &.excel {
    color: #00b140;
  }
  &.code {
    color: #009ca6;
  }
  &.archive {
    color: #ecc400;
  }
  &.pdf {
    color: #e4002b;
  }
  &.text {
    color: #685bc7;
  }
  &.csv {
    color: #685bc7;
  }
  &.video {
    color: #c800a1;
  }
  &.image {
    color: #ff6900;
  }
  &.audio {
    color: #0057b8;
  }
  &.other {
    color: #abafb3;
  }
}
