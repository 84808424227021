@import '~assets/styles/variables';

.container {
  max-width: 100vw;
  .viewer {
    border-top: $rule;
    border-bottom: $rule;
    background-color: $palette--bg--secondary;
    padding-bottom: 4rem;
    text-align: center;
    max-width: 100vw;
    .scan {
      padding: 2rem;
      overflow: hidden;
      user-select: none;

      .pdf {
        outline: none;
      }
    }
  }
}
