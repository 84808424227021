.highlight {
  position: absolute;
  background-color: yellow;
  height: 100%;
  width: 100%;
  left: 0;
}
.cell {
  z-index: 2;
}
