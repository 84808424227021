.progress-bar {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  &__label {
    display: flex;
    color: $palette--text--secondary;
    padding: 1em;
    font-size: 0.85rem;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    min-width: 125px;

    span {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__chip {
    background: $palette--accent;
    height: 15px;
    width: 15px;
    margin: 0 10px;
    border-radius: 2px;
    flex-shrink: 0;
  }
  &__msg {
    padding-left: 2em;
    min-width: 200px;
    color: $palette--text--secondary;
    font-size: 0.85rem;
  }
  &__container {
    width: 100%;
    max-width: 100%;
    height: 8px;
    background: $palette--bg--secondary;
    box-shadow: inset 0px 0px 0px 0.5px $palette--gray--light;
    border-radius: 25px;
  }
  &__fill {
    width: 0;
    max-width: 100%;
    height: 8px;
    background: $palette--accent;
    overflow: hidden;
    border-radius: 25px;
    transition: width 0.15s linear;
  }
  &__expand-button {
    width: 25px;
    height: 25px;
    min-height: 25px;
    min-width: 25px;
    padding: 0;
    color: $palette--text--secondary;
    margin: 5px;
    &:hover {
      background: $palette--bg;
    }
  }
}
