@import '~assets/styles/variables';

.schedule-picker {
  margin: 35px 0 25px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    max-width: 420px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: $shadow--small;
    background-color: $color--background;
    border: 1px solid $color--primary;
    div {
      color: $color--primary--dark;
    }
    &:hover {
      box-shadow: $shadow--medium;
    }
    &--selected {
      box-shadow: $shadow--medium;
      background-color: $color--accent--light;
      border: 1px solid $color--accent--dark;
      div {
        color: $color--white;
      }
    }
  }
}
