@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';


.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color--background;
  border: 1px solid $color--gray--light;
  box-shadow: $shadow--small;
  border-radius: $border-radius--medium;
  padding: 5px;
}

.text-container {
  padding: 5px 25px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: $spacing--medium;
  &__positions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.25rem;
  }
  &__archive {
    margin: auto 0;
  }
}
