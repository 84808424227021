@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.container {
  position: relative;
  display: flex;
}

.user {
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  &:hover {
    .avatar {
      box-shadow: 0px 0px 0px 2px $palette--gray--light;
    }
  }
  &:focus {
    outline: none;
    .avatar {
      border: 2px solid $color--accent--lightest;
    }
  }

  .avatar {
    border: 2px solid $color--white;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 1px $palette--gray--light;
    overflow: hidden;
    padding: 0;
    background-color: $color--white;
    transition: box-shadow 75ms ease-in-out;
    img {
      width: 34px;
      height: 34px;
      object-fit: cover;
    }
    svg {
      padding: 5px;
      width: 34px;
      height: 34px;
      vertical-align: middle;
    }
    &--default {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($palette--accent, 0.2);
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  border-bottom: $rule--full;
}

.link {
  padding: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  color: $palette--text--secondary;
  transition: all 175ms ease-in-out;
  width: 100%;
  svg {
    font-size: 13px !important;
    color: $palette--text--disabled;
    transition: all 175ms ease-in-out;
  }
  &:focus,
  &:hover {
    color: $palette--primary;
    background: $color--background;
    svg {
      color: $palette--heading;
    }
  }
}

.dropdown {
  left: 3.25rem;
  bottom: 0.5rem;
}

.icon-container {
  background: #48b0f7;
  color: white;
  height: 2em;
  width: 2em;
  padding: 0.25em;
  border-radius: 2em;
  margin-left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
