@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.header {
  height: 60px;
  background-color: $color--white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  .logo {
    text-align: center;
    margin-left: 1rem;
    img {
      height: 30px;
      width: auto;
      margin: 2rem 1rem;
    }
  }
  &--collapsed {
    justify-content: center;
    .logo {
      margin: 1rem;
      img {
        height: 50px;
        width: auto;
      }
    }
  }
}

.toggle {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  &:focus {
    outline: none;
  }
  &__text {
    display: flex;
    align-items: center;
  }
  &--collapsed {
    position: absolute;
    top: -1px;
    left: 100px;
    z-index: $z-index--control-mark;
    background-color: $color--white;
    border: $border--small;
    border-left: none;
    box-shadow: $shadow--small;
    border-bottom-right-radius: $border-radius--medium;
  }
}
