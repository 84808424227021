@import '~assets/styles/variables';

.container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 0.5rem;
  .field {
    flex: 1;
  }
  .error {
    flex: 1 0 100%;
    white-space: pre-line;
  }
  & > [data-field-block-role="field--no-label"] {
    margin-top: 1.6rem;
    margin-bottom: 1rem;
  }
}
