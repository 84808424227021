@import '~assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .icon {
    position: relative;
    top: -0.125em;
    font-size: 1.2rem;
  }
  .title {
    @extend %font__h3;
    padding: 0 0.5rem;
    margin-bottom: 0;
  }
  .actions {
    margin-left: 1rem;
  }
}

.body {
  background: $palette--bg;
  max-height: 4000px;
  transition: max-height 300ms ease-in-out;
  overflow: scroll;
  &--closed {
    max-height: 0;
    transition: max-height 300ms ease-in-out;
    overflow: hidden;
  }
  :global .md-list {
    box-shadow: none;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 1em 0.5em 0.5em;
}

.button {
  background: none !important;
  border: none !important;
}
