@import '~assets/styles/variables';

.label {
  margin-bottom: 5px;
}

.rates {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.rate {
  height: 30px;
  width: 30px;
  margin-right: 8px;
  background: $palette--bg--secondary;
  border-radius: 3px;
  border: 1px solid #e5e5e5;

  &--light {
    background: #92d4f3;
  }

  &--medium {
    background: #259feb;
  }

  &--dark {
    background: #204a6b;
  }
}
