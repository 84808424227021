@import '~assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;

  @media #{$breakpoint--sm} {
    flex-direction: column;
  }
}

.avatar-container {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.charts-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-left: $rule;
  padding: $spacing;

  @media #{$breakpoint--sm} {
    flex-direction: column;
    border-left: 0;
    border-top: $rule;
  }
}

.charts-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
}
