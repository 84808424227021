@import '~assets/styles/variables';

.summary {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  border-left: none;
  border-right: none;
  padding: 1rem 0;
  @include layout--small {
    flex-flow: column;
  }
}

.positive {
  color: $color--positive !important;
}

.negative {
  color: $color--negative !important;
}
