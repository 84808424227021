.header {
  display: flex;
  align-items: center;
}

.guest-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
