@import '~assets/styles/variables';

.bar {
  background-color: rgba($palette--warn, 0.2);
  color: darken($palette--warn, 10%);
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-size: 14px;
  border: 1px solid rgba($palette--warn, 0.2);
  border-radius: 5px;
  justify-content: space-between;

  button {
    color: $palette--warn;
    padding: 0 0 0 1rem;
    margin: 0;
    appearance: none;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    font-size: 13px;
  }
}
