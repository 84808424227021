@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.script-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 1em;
  background: $color--background;
  border-radius: 4px;
  border: 3px solid #eee;
  h4 {
    @extend %font__h4;
  }
  &__container {
    margin: $spacing;
  }
  &__section {
    align-items: center;
    margin: 2em 0 1.5em;
    border-bottom: $border--small;
    &__header {
      align-self: flex-end;
      margin: 1em 0;
    }
  }
  &__icon {
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    padding-left: 1em;
    align-self: flex-start;
    font-size: 1.5em;
  }
  &__content {
    flex: 1;
  }
  &__choices {
    margin: $spacing;
    &__label {
      @extend .muted;
      margin-top: 2em;
    }
    &__edit-field {
      display: flex;
      align-items: center;
      :global(.md-text-field-container) {
        margin: 1em;
      }
    }
    :global(.md-text--disabled) span {
      color: $color--gray--darkest;
    }
  }
  &__wrapper {
    display: flex;
    margin-top: 1rem;
  }
  &__actions,
  &__reorder-arrows {
    display: none;
  }
  &__container--edit {
    .script-item {
      &__wrapper:hover .script-item {
        border: 3px dashed rgba(0, 0, 0, 0.12);
      }
      &__actions {
        display: flex;
        padding: 0 1em;
        margin-left: auto;
        align-items: center;
      }
      &__reorder-arrows {
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 0 1em;

        & > *:not(:first-child) {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
