@import '~assets/styles/variables';

.viewer {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: $palette--bg--secondary;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  outline: $rule--full;

  &--loading {
    @include animate--shimmer;
  }
}

.download {
  padding: 1em 2em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: $rule--full;
  background-color: $palette--header-bg;
}

.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  align-self: stretch;
  border-top: $rule--full;
  background-color: $palette--header-bg;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: $box-shadow;
}

.document {
  margin: 2rem;
  max-width: 100%;

  &--with-header {
    margin-top: 1.5rem;
  }

  &--with-footer {
    margin-bottom: 1.5rem;
  }
}

.page {
  canvas {
    border: $rule;
    box-shadow: $box-shadow;
    max-width: 100%;
    height: auto !important;
  }
}

.por-removed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 15rem;
  &--icon {
    width: 5rem;
    height: 4rem;
    color: $color--primary--light;
  }
}
