.date-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 7px;
  &__label {
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
    position: absolute;
    bottom: 4px;
    transition-duration: 0.15s;
    transition-property: transform, font-size, color;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    transition: bottom 200ms ease-out;
    .md-icon-text {
      color: $palette--text--secondary;
    }
  }
  input {
    margin-top: 5px;
  }
  &--has-value {
    .date-picker__label {
      bottom: 28px;
      transition: bottom 200ms ease-out;
    }
    input {
      position: relative;
      bottom: -4px;
    }
  }
  .clear-dates {
    position: absolute;
    right: 4px;
    top: 0.2em;
    padding: 0px;
    height: 20px;
    width: 20px;
    .md-icon {
      font-size: 12px;
    }
  }
}
//=====Flatpickr Overrides
.flatpickr {
  @extend %font__small;
  &-input {
    border: none;
    width: 100%;
    background: transparent;
    color: $color--gray--darkest;
    &[disabled] {
      color: $color--gray;
      cursor: default;
    }
  }
  &-day,
  &-day:hover,
  &-day.selected {
    border-radius: 10%;
  }
  &-day.selected {
    background: $palette--primary !important;
    border: none;
    color: white;
  }
  &-month,
  &-weekdays,
  &-weekday {
    background: white !important;
  }
  &-weekday {
    color: $palette--primary !important;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $font-family--display;
    letter-spacing: 0.02em;
    padding: 0;
  }
  &-weekdays {
    padding-top: 10px;
  }
  &-month {
    margin-top: 10px;
  }
}
.cur-year,
.cur-month {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: 900 !important;
}
