@import '~assets/styles/variables';

.positive-feedback {
  color: $color--accent;
}

.negative-feedback {
  color: $color--negative;
}

.center-container {
  position: relative;
}

.centered-item {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
