@import '~assets/styles/variables';

.container {
  margin-top: 1.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.label {
  @extend %hint-text;
}

.form {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.input {
  display: none !important;
}

.avatar {
  border: 2px solid $color--white;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 1px $palette--gray--light;
  overflow: hidden;
  padding: 0;
  background-color: $color--white;
  margin-right: 1rem;

  &--loading {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px $palette--accent;
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  svg {
    padding-top: 8px;
    width: 48px;
    height: 48px;
    fill: $palette--primary;
    vertical-align: middle;
  }

  &--default {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($palette--primary, 0.2);
  }
}
