@import '~assets/styles/variables';

.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: auto;
  z-index: 3;
  display: flex;
}

.map {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.loadbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.canvas {
  flex: 1;
  min-height: auto;
}

.legend {
  position: absolute;
  left: 0rem;
  right: 0rem;
  margin-left: auto;
  margin-right: auto;
  width: 90% !important;
  bottom: 4rem;
  border-radius: 0.5rem !important;
  background-color: rgba(252, 252, 252, 0.75) !important;
  &__header {
    padding: $spacing;
  }
  &__content {
    display: flex;
    flex-flow: row wrap;
    row-gap: 1rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    &__box {
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      margin-right: 1rem;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }
    & > * {
      display: flex;
      margin-right: 5%;
    }
    &__four-plus-items {
      & > * {
        background-color: $color--negative;
      }
    }
    &__two-to-four-items {
      & > * {
        background-color: $color--warn;
      }
    }
    &__one-to-two-items {
      & > * {
        background-color: $color--primary;
      }
    }
    &__less-than-one-item {
      & > * {
        background-color: $color--accent;
      }
    }
    &__no-shifts {
      & > * {
        background-color: $color--background--darkest;
      }
    }
  }
  &__disclaimer {
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    text-align: end;
  }
}
