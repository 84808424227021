@import '~assets/styles/variables';

.container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 0.5rem;
  align-items: flex-end;
  .field {
    flex: 1;
  }
  .error {
    flex: 1 0 100%;
    white-space: pre-line;
  }
}

.container--deprecated {
  .input {
    grid-template-columns: 1fr 1fr 1fr 0.75fr;
    display: grid !important;

    :global {
      .md-text-field-container,
      .md-menu-container,
      .md-select-field__toggle {
        min-width: auto !important;
        margin-right: 1em !important;
        align-self: end;
      }
    }
  }
  .error {
    padding-top: 1em;
    color: $palette--warn;
    white-space: pre-line;
  }
}

.clearButton {
  align-self: end;
}
