@import '~assets/styles/variables';

.container {
  display: flex;
  margin: $spacing;
  align-items: center;
  flex: 1;
  min-height: 40px;
  & > * {
    margin-left: 0.75em;
    &:first-child {
      margin-left: 0;
    }
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-left {
    justify-content: flex-start;
  }
  &--justify-right {
    justify-content: flex-end;
  }
  &--justify-space-between {
    justify-content: space-between;
  }
}
