.field {
  display: flex;
  padding: 2px 0 0;

  > * {
    margin-right: 1.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
