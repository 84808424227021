@import '~assets/styles/variables';

.item {
  &.event {
    background-color: #6d5cae;
    &.past {
      background-color: #413768;
    }
  }
  &.meeting {
    background-color: #f8d053;
    &.past {
      background-color: #957d32;
    }
  }
}
