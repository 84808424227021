@import '~assets/styles/variables';

@keyframes flashandhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    text-decoration: underline;
    margin-left: 40px;
  }
}

.container {
  position: relative;
}

.message {
  animation: flashandhide 600ms 3s;
  animation-fill-mode: forwards;
  position: absolute;
  @extend %font__h4;
  .md-icon-text {
    padding: 2rem;
  }
}
