@import '~assets/styles/variables';

.panels {
  position: relative;
  display: grid;
  grid-gap: 0;
  grid-template-columns: minmax(350px, 3fr) minmax(200px, 7fr);
  & > section:first-child {
    border-right: $rule;
  }
}
