@import '~assets/styles/variables';

.rates {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 10px;
}

.legend {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;

    > span {
      margin-right: 10px;
    }

    > div {
      width: 1.75rem;
    }
  }
}
