@import '~assets/styles/variables';

:global {
  .BaseTable {
    box-shadow: none !important;
    border-top: $border--small;
  }
  .BaseTable__table-main {
    outline: none !important;
  }
}

.totals {
  display: flex;
  flex-direction: column;
  .row {
    padding-top: 1rem;
  }
}

.checkbox {
  margin: auto;
  :global(input) {
    display: none;
  }
}
