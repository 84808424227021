@import '~assets/styles/variables';

.avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: $spacing;
}

.name {
  @extend %font__h4;
  margin-left: 1rem;
}

.subtitle {
  margin-left: 1rem;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  svg {
    padding-top: 8px;
    height: 40px;
    width: 40px;
    fill: $palette--primary;
  }
  img {
    width: 100%;
    height: auto;
  }
  &--large {
    width: 65px;
    height: 65px;

    svg {
      padding-top: 10px;
      height: 65px;
      width: 65px;
    }
  }
  &--default {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($palette--primary, 0.2);
  }
}
