@import '~assets/styles/variables';

.two-panel {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & > * {
    flex: 1;
  }
  @include layout--medium {
    justify-content: flex-start;
    flex-direction: column;
  }
}

.pdf-header {
  background: $color--white;
  border: $border--small;
  box-shadow: $shadow--small;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
}

.button-hint {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.error-text {
  color: $color--negative !important;
}

.hr {
  border: $border--small;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.paginator {
  &__container {
    display: flex;
    align-items: center;
  }
  &__select {
    max-width: 100px;
    margin: 0 1rem;
  }
}

.sticky {
  position: sticky;
  top: 0;
  background: $color--white;
  border: $border--small;
  z-index: $z-index--sticky;
  &__actions {
    display: flex;
    justify-content: space-between;
  }
}

.footer-fix {
  margin-bottom: 0 !important;
}

.sticky-footer {
  position: sticky;
  bottom: 0px;
  background-color: #ffffff;
  padding: 1em;
  border: 1px solid #dae0e2;
}

.document-container {
  border-bottom: 0px;
}

.data-edit {
  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color--accent;
    .text {
      color: $color--white;
      margin: $spacing--medium;
    }
  }
}
