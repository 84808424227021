@import '~assets/styles/variables';

.scroll-list__item--date-status {
  display: flex;
  justify-content: space-between;
}

.form-viewer__packet-content__header__title-date-status-block {
  grid-column: 1 / span 2;
}

.form-viewer__packet-content__header__date-status-line {
  display: flex;
  justify-content: space-between;
}
