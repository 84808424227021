.body {
  max-height: 1000px;
  transition: max-height 300ms ease-in-out;

  &--closed {
    max-height: 0;
    transition: max-height 300ms ease-in-out;
    overflow: hidden;
  }
}

.button {
  & > div {
    display: flex;
  }
}
