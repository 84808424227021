@import '~assets/styles/variables';

.container {
  display: flex;
  justify-content: center;
  .summary {
    background-color: $color--white;
    border: $border--small;
    border-radius: $border-radius--medium;
    box-shadow: $shadow--small;
    flex: 0.5 0;
    @include layout--medium {
      flex: 1;
    }
    .label {
      padding: 2rem 2rem;
      @extend %font__h4;
    }
    .counts {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      padding: 0 1rem;
      @include layout--small {
        flex-direction: column;
        align-content: center;
      }
    }
  }
}
