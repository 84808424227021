@import '~assets/styles/variables';

.operator {
  .info {
    display: flex;
  }
  .highlight {
    text-align: center;
    margin: 2rem 0;
  }
  .chart {
    display: flex;
    justify-content: space-between;
    margin: 0 15%;
    margin-bottom: 2rem;
    @include layout--medium {
      flex-flow: column;
    }
    &__main {
      flex: 8;
      margin: 1rem;
    }
    &__legend {
      flex: 1;
      margin: auto;
    }
  }
}

.image {
  max-width: 750px;
}

.list {
  padding-left: 1rem;
  :global {
    li {
      margin-bottom: 1rem;
    }
  }
}

.scroll-container {
  margin: $spacing--medium;
  border: $border--small;
  border-radius: $border-radius--medium;
  box-shadow: $shadow--inset--small;
  max-height: 200px;
  overflow: scroll;
}

.socket-disconnect-warning {
  color: $color--negative--dark !important;
}
