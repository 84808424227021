@import '~assets/styles/variables';

.calendar {
  position: relative;
  border-top: $rule--full;
  border-left: $rule--full;
  background-color: $color--white;
}

.items {
  display: block;
  position: relative;
  z-index: 1;
}

.day {
  position: relative;
  color: $palette--text;
  min-height: 50vh;
  padding: 5px 0;
  @extend %hint-text;

  &.day--today {
    &:before {
      background-color: rgba($palette--accent, 0.1);
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 1px;
    bottom: 1px;
    background-color: $palette--bg;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-bottom: $rule--full;
    border-right: $rule--full;
  }
}
