@import '~assets/styles/variables';

.week {
  position: relative;
  min-height: 6rem;
  padding-bottom: 1px;
  padding-top: 5px;

  &--withMarker {
    padding-top: 1.75rem;
  }
}

.days {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.day {
  position: relative;
  border-bottom: $rule--full;
  border-right: $rule--full;
  color: $palette--text;
  @extend %hint-text;

  &--overflow {
    .marker {
      color: $palette--text--disabled;
    }
  }

  &--today {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($palette--accent, 0.1);
    }
  }

  .marker {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    height: 1.75rem;
    z-index: 1;
  }
}
