@import '~assets/styles/variables';

.header {
  border-bottom: $border--small;
  display: flex;
  align-items: flex-end;
  color: $color--gray--darkest;
  .header__icon {
    line-height: 1.5;
    svg {
      color: $color--gray--dark;
    }
  }
}
