@import '~assets/styles/variables';

.compare {
  max-width: 100vw;
  .container {
    border-top: $rule;
    border-bottom: $rule;
    background-color: $palette--bg--secondary;
  }
  .scans {
    padding: 1rem 2rem 2rem;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}
