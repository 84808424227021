@import '~assets/styles/variables';

.container {
  display: grid;
  grid-template-columns: minmax(300px, 1fr);
  grid-gap: 2em 8px;
  padding: $spacing;
  padding-top: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  position: relative;
  &--loading {
    opacity: 0.5;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 10%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 90%
      );
      background-size: 800px 100%;
      animation: shimmer 2.5s infinite ease-in;
      pointer-events: none;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
