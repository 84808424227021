@import '~assets/styles/variables';

.container {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;

  &--root {
    padding: 0;
  }
}

.preview {
  position: absolute;
  pointer-events: none;
  border-radius: 3px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.item {
  position: relative;
  display: flex;
  align-items: stretch;
  padding-left: 4px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: none !important;
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: -0.125rem 0 -0.125rem 0.125rem;

  &:hover,
  &--open {
    background-color: $color--background;
    border: 2px solid $color--gray--light;
  }

  &--inside {
    border: 2px solid $color--accent;
    background-color: rgba($color--accent, 0.25);
  }

  &--descendant {
    opacity: 0.45;
  }

  &--clipboard {
    border: 2px solid $color--gray--light;
    border-style: dashed !important;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 0 0.75rem 0 0;
    flex: 1;
    color: $color--gray--darkest;
    line-height: 24px;
    white-space: nowrap;
  }

  .icon {
    width: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 0.375rem;
    margin-right: 0.5rem;
    fill: $color--accent;
  }

  .chevron {
    flex: 0;
    border: none;
    padding: 0.25rem;
    color: $color--gray;
    background-color: transparent;
    position: absolute;
    left: -21px;
    top: 2px;

    svg {
      font-size: 11px !important;
      background-color: $color--background--dark;
    }
  }
}

.before,
.after {
  background-color: $color--accent;
  width: calc(100% - 12px);
  height: 4px;
  border-radius: 6px;
  position: absolute;
  left: 6px;
}

.before {
  top: -2px;
}

.after {
  bottom: -2px;
}
