@import '~assets/styles/variables';

.item {
  &:first-of-type {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:last-of-type {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    > button {
      border-bottom: 0;
    }
  }
}

.category__drawer {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 1rem;
  padding: $spacing--medium;
  border: $border--small;
  border-bottom: $border--medium;
  background: $color--background;
  &:focus {
    outline: thin solid $color--primary--lightest;
  }
  .icon {
    display: flex;
  }
}

.checkbox {
  margin: $spacing--medium;
}

.children {
  background-color: $color--background;
  border-bottom: $border--small;
  margin-top: 1rem;
}

.subcategory {
  margin-bottom: 2rem;
}
