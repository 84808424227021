.quality-control__packet-management {
  &__modal {
    display: block;
    height: 90vh;
    min-width: 65vw;
    
    .dialog--modal__content {
      flex: 1;
    }
    &__schedule {
      min-width: 50vw;
    }
    &__search {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5rem;
      &__select {
        flex: 1;
        margin-top: 1rem;
      }
      &__menu {
        flex: 0;
        margin-left: 1rem;
      }
    }
    &--qc-office {
      &__list {
        margin-top: 2rem;
      }
    }
  }
}
