@import '~assets/styles/variables';

.list {
  display: flex;
  padding-left: calc(2em + 1px);
  align-items: center;

  a {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 1rem 0.5rem;
  }
}

.personCount {
  text-align: right;
  color: $palette--heading;
  display: flex;

  > * {
    padding-right: 1rem;
  }

  span {
    min-width: 2.5rem;
  }
}

.footer {
  display: flex;
  padding: 0.75rem 1.5rem 0.5rem;
  align-items: center;
  justify-content: flex-end;
}
