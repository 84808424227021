@import '~assets/styles/variables';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(180deg, #FFFFFF 33.88%, #F3FBFD 46.35%, #B6D4DE 100%);
}

.top-bar {
  display: flex;
  justify-content: center;
  .logo {
    width: 120px;
    height: auto;
    margin: $spacing--medium;
    z-index: 1;
    position: relative;
  }
}

.title {
  text-align: center;
  margin-top: 6rem;
  max-width: none !important;
  z-index: 1;
  position: relative;
}

.message {
  text-align: center;
  margin-top: 4rem;
  z-index: 1;
  position: relative;
}

.wrench {
  position: absolute;
  bottom: 15vh;
  z-index: 0;
  height: 30vh;
  width: auto;
}

.edge-piece {
  position: absolute;
  z-index: 0;
  width: 25px;
  height: auto;
  &--left {
    left: 0;
    top: 10vh;
  }
  &--right {
    right: 0;
    bottom: 10vh;
  }
}
