@import '~assets/styles/variables';

.container {
  width: 100%;
}

.error {
  @extend %font__small;
  padding: 0.5rem 1rem 0;
  color: $palette--warn;
}
