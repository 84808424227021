@import '~assets/styles/variables';

.card {
  &__error {
    padding: 1rem 0.5rem;
    margin: 1em;
    border: 2px solid $palette--warn;
    border-radius: 3px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    &__text-container {
      flex-grow: 3;
      margin: auto 1em;
      color: $palette--warn;
      text-align: left;
    }
    &__button {
      flex-grow: 0;
      margin-left: auto;
      margin-right: auto;
      padding: 0 1rem;
    }
    &__label {
      color: $palette--warn;
      font-weight: 700;
    }
  }
}
