@import '~assets/styles/variables';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $palette--bg;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 1rem;
}

.title {
  font-size: 1.5rem;
  line-height: 1.4;
  color: $palette--heading;
  display: block;
  font-weight: 600;

  @media #{$breakpoint--sm} {
    font-size: 1.25rem;
  }
}

.logo {
  height: 33px;
}

.panel {
  max-width: 620px;
  border: $rule--full;
  padding: 1rem 0;
  margin-top: 1.25rem;

  .message {
    font-size: 1.1rem;
    line-height: 1.6;
    color: $palette--text;
    display: block;

    strong {
      font-weight: 700;
    }

    @media #{$breakpoint--sm} {
      font-size: 1rem;
    }
  }

  .success {
    color: $palette--success;
    font-weight: 600;

    @media #{$breakpoint--sm} {
      font-size: 1rem;
    }
  }

  .error {
    color: $palette--warn;
    font-weight: 600;

    @media #{$breakpoint--sm} {
      font-size: 1rem;
    }
  }

  .actions {
    padding-top: 1rem;

    @media #{$breakpoint--sm} {
      padding-top: 0.75rem;
    }
  }
}
