@import '~assets/styles/variables';

.handle {
  margin-bottom: 1rem;
  cursor: move;
  transition: opacity 0.12s ease;
  min-width: 300px;
  opacity: 1;
  background-color: $color--white;
  border: $rule--full;
  box-shadow: $box-shadow--card;

  &--isDragging {
    opacity: 0.6;
  }
}

.zone {
  background-color: $palette--bg--hover;
  border-left: $rule--full;

  :global {
    .md-panel-header {
      padding: 0 1.5rem;
    }

    .md-expansion-panel {
      padding: 0;
      box-shadow: none;
      border-bottom: $rule--full;
      background-color: $palette--bg--hover;
      min-width: calc(300px + 3rem);

      &--expanded {
        margin: 0 !important;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .md-panel-content {
      padding: 0.25rem 1.5rem 1.5rem;

      > div:last-of-type {
        margin-bottom: 0rem;
      }
    }
  }
}

.instructions {
  @extend %hint-text;
  padding: 1rem 1.5rem 0.5rem;
  display: block;
  font-weight: 600;
}

.empty {
  @extend %hint-text;
  font-weight: 600;
}
