@import '~assets/styles/variables';

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1em;
  border-top: $rule;
  border-bottom: $rule;
  background: white;
}

.title {
  @extend %font__h4;
}
