.summary-display {
  padding: 2em;
  margin-right: 2em;
  &__figure {
    @extend %font__display;
    margin-bottom: 1rem;
  }
  &__label {
    @extend %font__h4;
    @extend .muted;
  }
}
