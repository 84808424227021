@import '~assets/styles/variables';

.legend {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1em;
}

.item {
  padding-top: 4rem;
  position: relative;

  &.voter_registration {
    background-color: $color--accent--dark;
  }
  &.petitions {
    background-color: $color--primary--dark;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border: $rule--full;
  }
}

.label {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  background-color: $color--white;
  font-weight: 600;
}
