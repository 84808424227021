@import '~assets/styles/variables';

.item-text {
  margin: 2em;
  max-width: 600px;
}

.save-button {
  margin-top: 4rem;
}
