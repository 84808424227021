@import '~assets/styles/variables';

.item {
  &--archived {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &--disabled {
      color: $color--gray;
    }
    &__icon {
      color: $color--gray--dark;
      display: flex;
      align-items: center;
    }
  }
}
