@import '~assets/styles/variables';

.items {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.item {
  position: relative;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 6px;
  overflow: hidden;

  &.item--startOverflow {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;

    > * {
      padding-left: 10px;
    }
  }
  &.item--endOverflow {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0px;

    > * {
      padding-right: 10px;
    }
  }

  > * {
    @extend %hint-text;
    display: block;
    color: $color--white;
    padding: 2px 4px;
    background-color: $palette--accent;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;

    &:hover {
      color: $color--white;
    }
  }

  > .more {
    background-color: transparent;
    font-weight: 600;
    font-size: 12px;
    border: 0;
    color: $palette--accent;
    padding: 0px 6px;
  }
}
