@import '~assets/styles/variables';

.animation-container {
  position: relative;
}

.animation-block {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  &--animated {
    z-index: 1;
    background-color: white;
    @keyframes shine {
      0% {
        opacity: 0.8;
        left: 0;
        transition-property: left, opacity;
      }
      100% {
        opacity: 0;
        left: 100%;
      }
    }
    animation: shine 0.9s ease;
  }
}
