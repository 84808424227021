@import '~assets/styles/variables';

.card {
  margin: 1rem 0 1rem 1rem;
  width: 100%;
  max-width: 300px;
  background-color: $color--background--dark;
  border-radius: $border-radius--medium;
  overflow: hidden;
  box-shadow: $shadow--medium;
  &__title {
    background-color: $color--background--dark;
    display: flex;
    padding: 1rem;
  }
  &__content {
    background-color: $color--background--dark;
    padding-left: 1rem;
    padding-top: 0;
    &__text {
      background-color: $color--white;
      border: $border--small;
      border-radius: $border-radius--medium;
      width: 100%;
      display: flex;
      align-content: space-between;
      padding: 0.5rem;
    }
  }
}
