.date-range-selector {
  z-index: 3;
  display: flex;
  flex-flow: column;
  &__label {
    @extend %font__small;
    margin-bottom: 0;
  }
  strong {
    color: #6f7b8a;
    font-size: 13px;
    font-family: 'Helvetica Neue';
    text-transform: uppercase;
  }
  small {
    color: #6d5cae;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat';
    letter-spacing: 0.02em;
  }
  .presets-container {
    .md-btn {
      margin: 0 1em 1em 0;
    }
  }
  .DateRangePicker {
    border-bottom: thin solid $palette--gray--light;
  }
  .DateRangePickerInput {
    width: 100%;
    &_button {
      padding: 4px 12px;
      margin-right: 8;
      font-weight: 400;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 13px;
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
      color: #08c;
    }
    &_calendarIcon {
      margin-left: 0;
    }
    &_button:hover {
      background: #08c;
      border: 1px solid #08c;
      color: #fff;
    }
  }

  .DayPicker_weekHeaders {
    padding-bottom: 5px;
  }
  .CalendarDay {
    &__default {
      border: none;
    }
    &__selected,
    &__selected:hover,
    &__selected_span:hover {
      border-radius: 3px;
      background: #6d5cae;
      color: #fff;
      border: #6d5cae;
    }
    &__hovered_span,
    &__hovered_span:hover,
    &__selected_span {
      background: #ebf4f8;
      color: #565a5c;
    }
  }
}
