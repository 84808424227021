@import '~assets/styles/variables';

.duplicates {
  display: flex;
  overflow: scroll;
  padding-top: 1rem;
}

.duplicate {
  flex: 1 0 28vw;
  min-width: 400px;
  max-width: 600px;
}
