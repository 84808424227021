@import './colors';

// System overrides
$font-family--primary: $font-family--copy;

%font__display {
  @include font--display;
}

%font__h1 {
  @include font--display;
}

%font__h2 {
  @include font--display;
  font-size: 1.75em;
}

%font__h3 {
  @include font--display;
  font-size: 1.25em;
}

%font__h4 {
  @include font--display;
  font-size: 1.125em;
}

%font__paragraph {
  @include font--copy;
}

%font__small {
  @include font--copy;
  font-size: 12px;
}

%font__btn {
  @include font--action;
}

%uppercase {
  text-transform: uppercase;
}

%capitalize {
  text-transform: capitalize;
}

%lowercase {
  text-transform: lowercase;
}

%reading-text {
  max-width: 650px;
}

%label {
  color: $palette--text--secondary;
  font-size: 1em;
}

%hint-text {
  color: $palette--text--secondary;
  font-size: 13px;
}
