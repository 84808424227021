@import '~assets/styles/variables';

.page {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  &__column {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    @include layout--medium {
      display: flex;
      flex-flow: row wrap;
    }
  }
  &__item {
    width: 300px;
    height: 120px;
    margin: $spacing--medium;
    @include layout--medium {
      width: auto;
      flex-grow: 1;
    }
    &__field {
      margin-top: 1rem;
    }
  }
}
.positive {
  color: $color--positive !important;
}

.negative {
  color: $color--negative !important;
}
