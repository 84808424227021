// === react-md overrides and customizations ===
$md-font-family: $font-family--primary;
$md-light-theme: true;
$md-primary-color: $palette--primary;
$md-secondary-color: $palette--accent;
$md-font-icon-separator-padding: 0.5em;
$md-body-desktop-font-size: 16px;
@include react-md-everything;
.md-display-1,
.md-headline,
.md-title,
.md-subheading-2,
.md-subheading-1,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family--display;
  color: $palette--heading;
}

#delivery-single-table-pagination-menu-options {
  position: absolute;
  left: auto !important;
  top: auto !important;
  bottom: 100%;
}

.md-grid,
.md-cell {
  margin: 0;
  padding: 0 !important;
}

.md-tooltip {
  border-radius: 4px;
}

.md-icon-separator {
  .md-icon-text {
    color: $palette--text;
    padding-top: 3px;
  }
  .md-icon {
    padding-top: 2px;
  }
}
.md-background--primary,
.md-background--secondary {
  .md-icon-separator {
    & .md-icon-text,
    .md-icon {
      color: white;
    }
  }
}

.md-dialog--centered {
  .md-autocomplete-list {
    &.md-list {
      padding: 8px 0px;
    }
  }
}

.md-dialog {
  overflow: auto;
  min-width: 60%;
  .md-toolbar {
    background: white;
  }
  .md-dialog-content {
    overflow: visible;
  }
}

.md-divider {
  &--text-field {
    margin-bottom: 2px;
  }
}

.md-tabs {
  border-bottom: $rule;
  &-content {
    padding-top: 0;
    overflow-y: hidden;
  }
}

.md-tab {
  padding-top: 3em;
  border-bottom: none;
  .md-tab-label {
    @include font--action;
    color: $color--gray--dark;
  }
  &-panel {
    padding: 2em 0 0;
  }
  &--active {
    .md-tab-label {
      color: $color--accent;
    }
  }
}

.md-btn {
  width: inherit;
  &--icon {
    &.md-pointer--hover {
      &.md-background--secondary,
      &.md-background--primary {
        color: white;
      }
    }
    .md-icon {
      display: inherit;
    }
  }
  &--floating-mini {
    width: 48px;
    height: 48px;
  }
}

.md-autocomplete-list {
  width: auto;
}

.md-menu--select-field {
  .md-icon-text {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.md-progress {
  background: lighten($palette--accent, 20%);
  margin: 0;
  &--linear-active {
    background: $palette--accent;
  }
}

.md-table {
  &-body {
    background: $palette--bg;
  }
  &-column {
    position: relative;
  }
  &-pagination--controls {
    right: 0;
    margin-right: 1em;
    .md-menu--select-field {
      margin-top: 0;
    }
  }
}

.md-text {
  &--disabled {
    color: $color--gray;
  }
  &-field {
    &--floating-margin {
      margin-top: 1em;
    }
    &-icon--positioned {
      margin-bottom: 6px;
    }
    &-message-container {
      position: absolute;
      top: 2.75rem;
    }
  }
}

.md-floating-label {
  &--inactive {
    transform: translate3d(0, 1em, 0);
  }
  &--floating {
    transform: translate3d(0, -0.5em, 0);
  }
}
