@import '~assets/styles/variables';

.container {
  pointer-events: none;
  position: absolute;
  left: 2rem;
  top: 2rem;

  .button {
    opacity: 0;
    transition: all ease-in-out 0.125s;
    margin-top: -0.5rem;
    pointer-events: all;

    &--active {
      opacity: 1;
      margin-top: 0;
    }
  }
}
