@import '~assets/styles/variables';

.container {
  display: grid;
  grid-template-columns: minmax(400px, 1fr) 2fr;
  column-gap: 2rem;
  overflow: hidden;
  position: relative;
  &__main {
    padding-top: 4rem;
  }
  &__sidepanel {
    padding-top: 4rem;
    background-color: $color--background--dark;
    overflow: scroll;
  }
}

.header {
  margin-bottom: 0;
}
