@import '~assets/styles/variables';

.interface {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.buttons {
  overflow: hidden;
  border-radius: 5px;
  background-color: $color--white;
  box-shadow: 0 0 0 2px $palette--gray--light;

  .button {
    background-color: $color--white;
    border-radius: 0;
    height: 36px;
    width: 36px;
  }

  .separator {
    height: 1px;
    background-color: $palette--gray--light;
  }
}
