@import '~assets/styles/variables';

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.container {
  background-color: lighten($palette--accent, 20);
  width: 100%;
  overflow: hidden;
}

.progress {
  height: 3px;
  background-color: $palette--accent;
  transition: width 0.1s linear;

  &--mock {
    animation: progress 3s linear infinite;
  }
}
