.qc-notes {
  &__header {
    padding: $spacing;
    color: $palette--text--secondary;
    @extend .uppercase;
  }
  &__container {
    background-color: $palette--bg;
    padding-bottom: 1em;
  }
  &__item {
    @extend %row--spread;
    padding: $spacing;
    border-bottom: $rule;
    &:last-child {
      border-bottom: none;
    }
    &__content {
      @extend .reading-text;
    }
    &__source {
      @extend %font__small;
      color: $palette--text--secondary;
    }
  }
}
