@import '~assets/styles/variables';

.metric-display {
  text-align: center;
}

.metric-container {
  margin-bottom: 1em;
  min-width: 20vw;
  width: 20vw;
  background: #fcfcfc;
  border: 1px solid #dae0e2;
}

.chart-container {
  min-width: 30vw;
  width: 100vw;
  min-height: 25vh;
  max-height: 80vh;
  margin-right: 2rem;
}

.chart-section-container {
  display: flex;
}
