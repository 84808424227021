.detail-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid $palette--text--secondary;
  border-radius: 25px;
  margin: 0.5rem;
  width: 35px;
  height: 35px;
}
