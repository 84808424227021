@import '~assets/styles/variables';

.container {
  display: flex;
  flex: 0 1;
  @include layout--small {
    padding: 2rem 0;
  }
  .icon {
    flex: 1;
    padding-top: 1em;
    &__primary {
      color: $color--primary--light;
    }
    &__accent {
      color: $color--accent--light;
    }
    &__gray {
      color: $color--gray;
    }
  }
  .count {
    flex: 1;
    flex-shrink: 1;
    flex-direction: column;
    .number {
      box-shadow: 0px 0px 0px -2px rgba(0, 0, 0, 0.12),
        0px 1px 1px rgba(0, 0, 0, 0.06);
      @extend %font__h1;
      border: 2px solid rgba(0, 0, 0, 0.12);
      margin-left: 0.5em;
      margin-right: 0.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      width: 70px;
      background-color: #f8feff;
      margin-bottom: 0.2em;
      border-radius: 5px;
      color: #000;
    }
    .label {
      text-align: center;
    }
  }
}
