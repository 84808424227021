@import '~assets/styles/variables';

.card {
  padding-top: 1em;

  :global {
    .trained-states,
    .staff-languages {
      padding: $spacing;
    }
    .avatar {
      order: 1;
    }
    .trained-states {
      order: 2;
    }
    .staff-member-card__timezone {
      order: 3;
    }
    .staff-languages {
      order: 4;
    }
  }
}

.chart {
  padding: $spacing;
  flex-direction: column;
  align-items: flex-start;
  order: 5;

  .label {
    padding: 4px;
    p {
      @extend %font__small;
    }
  }
}
