@import '~assets/styles/variables';

.panel {
  padding: 0.75rem 1.5rem 1.75rem;
  background-color: $color--white;
  border: $rule--full;
  border-radius: 3px;

  &--full {
    height: 500px;
  }
}

.header {
  padding-bottom: 0.5rem;
}

.notice {
  padding: 1.5rem;
  font-weight: 600;
  color: $palette--text--secondary;
}

.content {
  background-color: $palette--bg;
  border: $rule--full;
  position: relative;

  &--loading {
    border: 0;
  }

  .loadbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
