@import '~assets/styles/variables';

.uploader {
  display: flex;
  justify-content: center;
  &__drag-area {
    border: 2px dashed $color--gray--light;
    margin: 2rem 4em;
    text-align: center;
  }
  &--hint-text {
    font-size: 12px;
    margin-left: 3.1rem;
  }
  &--disabled {
    position: relative;
    cursor: not-allowed;
    .uploader__drag-area {
      background: $color--gray--lightest;
    }
    :global {
      .uppy-DragDrop {
        &-container {
          background-color: $color--gray--light !important;
        }
        &-label,
        &-browse {
          text-decoration: none !important;
          color: $color--gray !important;
        }
      }
    }
    .scrim {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &__list {
    background-color: $color--background--dark;
    border: $border--small;
    border-radius: $border-radius--small;
  }
  :global {
    .uppy-DragDrop {
      &-container {
        border: none;
        background-color: $color--primary--lightest;
      }
      &-inner {
        padding: 8rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        input {
          margin-left: 3em;
          display: none;
        }
        svg {
          fill-opacity: 0.54;
        }
      }
      &-label {
        font-size: 1.5em;
        padding: 2em 0;
      }
      &-browse {
        text-decoration: underline;
        color: $color--accent;
      }
    }
    .file-inputs__upload-card {
      align-self: flex-start;
      margin: 2em auto;
      max-width: 320px;
      position: relative;
      width: 100%;
      &--application.md-card {
        background: $color--background--dark;
      }
      &__remove.md-btn {
        background: $color--primary--lightest;
        color: $color--primary;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
      }
      &__dummy-file.md-icon {
        left: 50%;
        position: absolute;
        top: 25%;
        transform: translateX(-50%);
      }
    }
  }
}
