@import '~assets/styles/variables';

.modal {
  min-width: 65vw;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.title {
  @extend %font__h3;
  color: $palette--text--secondary;
}
