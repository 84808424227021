@import '~assets/styles/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.confirmation {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $color--accent;
}
