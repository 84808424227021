@import '~assets/styles/variables';

.sort-arrow {
  display: flex;
  align-items: flex-end;
  color: $palette--text--secondary;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.sort-arrow > i {
  margin: 0.5rem;
}
