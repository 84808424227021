@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.hint {
  @extend .hint-text;
  margin-left: 2rem;
}

.meeting-form > * {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.guest {
  display: flex;
  .select {
    margin-right: 0;
  }
  .create {
    margin-left: 1em;
    > button {
      align-self: flex-start;
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
