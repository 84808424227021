@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.card {
  margin: $spacing;
  border-radius: 2px;
  border: $rule;
  box-shadow: $box-shadow;
  padding-bottom: inherit;
  margin-bottom: inherit;
  float: none;
  position: relative;
  margin-bottom: inherit;
  width: auto;
  &__title {
    @extend %font__h4;
    @extend .muted;
    padding: $spacing;
  }
  &__content {
    padding: $spacing;
  }
}
