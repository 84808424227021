@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.item {
  display: grid;
  grid-template-columns: 1.25fr 1fr 0.75fr;
  grid-template-rows: 1fr 1fr;
  padding-top: 1rem;
  border-bottom: $rule;
  @include layout--medium {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &__avatar {
    order: 0;
    @include layout--medium {
      grid-column: span 2;
    }
  }
  &__detail {
    order: 1;
    padding-top: 1.75rem;
    @include layout--medium {
      padding: $spacing;
    }
    &__timezone {
      text-transform: uppercase;
      @extend %font__small;
      color: $color--gray--dark;
    }
  }
  &__badges {
    order: 2;
    padding-top: 1em;
    margin-left: auto;
    margin-right: 2rem;
    @include layout--medium {
      order: 2;
    }
  }
  &__bar-chart {
    order: 3;
    padding: 1.5rem 2rem 1rem;
    align-self: start;
    @include layout--medium {
      padding: 0 2rem;
      grid-column: 1/-1;
    }
    &__label {
      padding-top: 1.15rem;
      align-self: start;
      @extend %font__small;
    }
  }
  &__permissions {
    order: 4;
    @include layout--medium {
      padding: $spacing;
    }
  }
  &__actions {
    order: 5;
    @include layout--medium {
      grid-column: 2/-1;
    }
    @include layout--small {
      grid-column: 1/-1;
    }
  }
  &__notice {
    order: 5;
    grid-column: 1/-1;
    background: $color--background--dark;
    border-bottom: 2px solid $color--gray--light;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
