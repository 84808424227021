@import '~assets/styles/variables';

.signature-block {
  &__header {
    display: flex;
    justify-content: space-between;
    border: $border--small;
    border-radius: $border-radius--small $border-radius--small 0 0;
    background-color: $color--background;
    & > * {
      flex: 1;
    }
  }
  &__container {
    position: relative;
    background-color: $color--background--light;
    border: $border--small;
    box-shadow: $shadow--small;
    border-radius: $border-radius--small;
  }
  &__content {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    gap: 2rem;
  }
  &__pin-section-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.signature-list {
  &__actions {
    display: flex;
  }
}

.loading-overlay {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  background: $color--background;
  opacity: 0.5;
  z-index: 1;
  display: none;
  &--visible {
    display: block;
    cursor: wait;
  }
}

.requirements {
  padding: 0;
  margin: 0;
}

.voter-match {
  &__container {
    margin: $spacing--medium;
    flex: 1;
    background-color: $color--background;
    border: $border--small;
    border-radius: $border-radius--small;
    box-shadow: $shadow--small;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$breakpoint--md} {
      flex-direction: column;
    }
  }
  &__field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__icon {
    font-size: 1.6rem;
    color: $palette--warn !important;
    &--match {
      font-size: 1.4rem;
      color: $palette--success !important;
    }
  }
}

.review-badge {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &--approved,
  &--approved__label {
    color: $palette--accent !important;
  }
  &--rejected,
  &--rejected__label {
    color: $palette--warn !important;
  }
  &__icon {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
