@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  display: flex;
  justify-content: center;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  @include quint-ease-out(all);
  &--visible {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    @include quint-ease-out(all, 650ms);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 12;
  max-height: 90vh;
  min-width: 35vw;
  max-width: 80vw;
  background: #fff;
  border-radius: 4px;
  box-shadow: $box-shadow--menu;
  overflow: scroll;
  transform: translateY(80%);
  pointer-events: none;
  opacity: 0;
  @include quint-ease-out(all, 300ms);
  &--visible {
    pointer-events: auto;
    transform: translateY(0);
    opacity: 1;
    @include quint-ease-out(all, 650ms);
  }
  &--overflow {
    overflow: visible;
    .content {
      overflow: visible;
    }
  }
}

.header,
.content,
.actions {
  flex: 0;
}
.header {
  @extend %font__h2;
  padding: $spacing;
}
.content {
  min-height: 100px;
  overflow: auto;
}
.actions {
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 1rem;
  }
}
