@import '~assets/styles/variables';

.question {
  margin-top: 2em;
  max-width: 600px;

  & > * {
    max-width: 600px;
  }
}

.choice {
  &__field {
    padding: 0.5em 1.25em 1em 0.5em;
  }
  &__add-button {
    margin-bottom: 4rem;
  }
}
