@import '~assets/styles/variables';

.container {
  width: 100%;
  height: 60px;
  background-color: $color--accent--light;
  border: $border--small;
  box-shadow: $shadow--medium;
  padding: $spacing--medium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.text {
  color: $color--accent--lightest !important;
}
