@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.dropdown {
  position: absolute;
  min-width: 200px;
  border-radius: 3px;
  z-index: $z-index--dropdown;
  margin-top: 0.5rem;
  opacity: 0;
  transform: scale(0.8);
  visibility: hidden;
  bottom: 0;
  @include quint-ease-in(opacity, 75ms, transform, 75ms);
  .content {
    overflow: auto;
    border-radius: 3px;
    box-shadow: $box-shadow--card, 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    background-color: $color--white;
  }

  &--open {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }

  &--left {
    transform-origin: top left;
  }
  &--top {
    transform-origin: top;
  }
  &--right {
    transform-origin: top right;
  }
}
