@import '~assets/styles/variables';

.item {
  width: 100%;
  cursor: pointer;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $palette--bg--secondary;
  padding: 1.5rem 2rem;

  &:hover {
    background: $palette--bg--hover;
  }

  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid $palette--bg--secondary;
  }

  &:last-child {
    border-bottom: none;
  }
}

.icon {
  margin-right: 5px;
}
