@import '~assets/styles/variables';

.modal {
  max-width: 60vw;
  overflow: auto;
}

.text {
  padding: 0 0 1.5rem;

  strong {
    color: $color--gray--dark;
  }
}
