@import 'react-md/src/scss/react-md';
@import '../variables/breakpoints';
@import '~assets/styles/variables';

html,
body {
  font-family: GT America, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  background: $color--background;
  margin: 0;
  min-width: 100%;
  color: $color--gray--darkest;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

html {
  font-size: 16px;
}

body {
  font-size: 16px;
}

@font-face {
  font-family: 'Avenir-Next';
  src: url('../../fonts/avenir-next/avenir-next-regular.woff2') format('woff2'),
    url('../../fonts/avenir-next/avenir-next-regular.woff') format('woff');
}
@font-face {
  font-family: 'Avenir-Next-Medium';
  src: url('../../fonts/avenir-next/avenir-next-medium.woff2') format('woff2'),
    url('../../fonts/avenir-next/avenir-next-medium.woff') format('woff');
}
@font-face {
  font-family: 'Avenir-Next-Bold';
  src: url('../../fonts/avenir-next/avenir-next-bold.woff2') format('woff2'),
    url('../../fonts/avenir-next/avenir-next-bold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next-Italic';
  src: url('../../fonts/avenir-next/avenir-next-italic.woff2') format('woff2'),
    url('../../fonts/avenir-next/avenir-next-italic.woff') format('woff');
}

// === Design System Fonts ===
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Regular.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Regular.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Regular-Italic.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Regular-Italic.woff2')
      format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Thin.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Thin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Thin-Italic.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Thin-Italic.woff2')
      format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Medium.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Medium.woff2')
      format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Bold.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../../fonts/GT-America/GT-America-Standard-Bold-Italic.woff')
      format('woff'),
    url('../../fonts/GT-America/GT-America-Standard-Bold-Italic.woff2')
      format('woff2');
  font-weight: 600;
  font-style: italic;
}

// === Variables ===
$font-family--primary: 'Avenir-Next', 'Segoe UI', Arial, sans-serif;
$font-family--display: 'Montserrat', 'Segoe UI', Arial, sans-serif;
$palette--bg: #fafafa;
$palette--bg--secondary: #ecf0f2;
$palette--bg--hover: #f9f9f9;
$palette--text: rgba(0, 0, 0, 0.87);
$palette--heading: rgba(0, 0, 0, 0.65);
$palette--text--secondary: rgba(0, 0, 0, 0.54);
$palette--text--disabled: rgba(0, 0, 0, 0.38);
$palette--gray--light: rgba(0, 0, 0, 0.12);
$palette--primary: rgb(109, 92, 174);
$palette--accent: #48b0f7;
$palette--secondary: $palette--accent;
$palette--warn: #f44336;
$palette--header-bg: #fff;
$palette--footer-bg: $palette--bg;
$palette--header-contrast: $palette--primary;
$palette--footer-headline: $palette--primary;
$palette--footer-contrast: $palette--primary;
$rule: 0.5px solid $palette--gray--light;
$box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.12),
  0px 2px 2px 0px rgba(0, 0, 0, 0.24);
$box-shadow--menu: 0 0 8px 0 rgba(0, 0, 0, 0.12),
  0 8px 8px 0 rgba(0, 0, 0, 0.24);
$box-shadow--drawer--right: -2px 2px 4px 0 rgba(0, 0, 0, 0.12);
$box-shadow--drawer--left: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);

// Blocks legacy styles overrides
.page-sidebar {
  z-index: 3;
}

.header {
  z-index: 2;
}

p {
  letter-spacing: 0.01em;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: $color--accent;
}

ul.summary-tabs.nav.nav-tabs.nav-tabs-simple::after,
.panel .panel-heading,
.form-group-default.form-group-default-select2 > label {
  z-index: 1;
}

.md-table-body {
  @extend %font__small;
}
