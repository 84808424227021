@import '~assets/styles/variables';

.shadow {
  position: sticky;
  top: 0;
  z-index: 3;
  @include transition-out(box-shadow);
  border-radius: $border-radius--medium;

  &.shadow--stuck {
    box-shadow: $shadow--medium;

    .container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .banner {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .container {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius--small;
  }

  .banner {
    background-color: $color--accent;
    border: 0;
    overflow: hidden;
    &--warn {
      background-color: $color--negative;
    }
    &__content {
      display: flex;
      align-items: center;
    }
    &__text {
      color: $color--accent--lightest;
    }
  }

  .loadbar {
    background-color: rgba($color--white, 0.2);
    position: absolute;
    top: 0;

    > div {
      background-color: rgba($color--white, 0.4);
    }
  }
}
