.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nf-image {
  width: 100%;
  height: auto;
  max-width: 427px;
  margin: 7vh 1rem 1.5rem 1rem;
}

.edge-piece {
  position: absolute;
  z-index: 0;
  width: 25px;
  height: auto;

  &--left {
    left: 0;
    top: 10vh;
  }

  &--right {
    right: 0;
    bottom: 10vh;
  }
}
